import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'
import { userService } from 'services'
import { _Object } from 'utils/interfaces'
import { Breadcrumbs, Button, InputField, LinkButton } from 'views/components'

const CreateCustomer = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)

  const [initialData, setInitialData] = useState<_Object>({})
  const { me } = useSelector((state: _Object) => state.session)


  useEffect(() => {
    setEditMode(false)
    setInitialData({
      email: '',
      last_name: '',
      first_name: '',
      status: 'active',
      mobile_number: '',
      location_id: me?.location?.id,
      // mobile_number: value ? value : '',
      city: '',
      state: '',
      address1: '',
      address2: '',
      postcode: '',
      full_name: '',
      company_name: '',
      alternate_number: '',
    })

    if (id) {
      setLoading(true)
      setEditMode(true)
      userService.getCustomersDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading(false)
      })
    }
  }, [location])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().trim().required('First name is required'),
      last_name: Yup.string().trim().required('Last name is required'),
      mobile_number: Yup.number()
        .min(10, 'Mobile number must be 10 numbers at minimum')
        .required('Mobile number is must be required'),
      email: Yup.string().email('Please enter valid email'),
    }),

    onSubmit: (values) => {
      values.email = values.email === '' ? `${values.mobile_number}@piecodes.in` : values.email
      // values.address1 = JSON.stringify({
      //   first_name: values.first_name,
      //   last_name: values.last_name,
      //   mobile_number: values.mobile_number,
      //   address2: values.address2,
      //   city: values.city,
      //   state: values.state,
      //   postcode: values.postcode
      // })

      setLoading(true)
      if (id) {
        userService.updateCustomers(id, values).then((data: _Object) => {
          setLoading(false)
          if (data.error === false) {
            navigate(`${routes.customers}`)
          } else {
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading((false))
        })
      } else {
        userService.createCustomers(values).then((data: _Object) => {
          setLoading(false)
          if (data.error === false) {
            navigate(`${routes.customers}`)
          } else {
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading((false))
        })
      }
    }
  })


  return (
    <>
      <Breadcrumbs
        trails={[
          {
            label: 'Customers',
            path: `${routes.customers}`
          },
          {
            label: 'New customers',
            path: ''
          },
        ]}
        pageTitle={'New customers'}
      />

      <form className={`row filters ${loading === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit} >
        <div className="col">
          <div className="card">
            <div className="card-header">
              <strong>General information</strong>
            </div>

            <div className="card-body">
              <div className="row">
                <InputField
                  name="first_name"
                  className="col-6"
                  label="First name"
                  required={true}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={formik?.touched.first_name && formik.errors.first_name && formik.errors.first_name}
                />

                <InputField
                  name="last_name"
                  className="col-6"
                  label="Last name"
                  required={true}
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  error={formik?.touched.last_name && formik.errors.last_name && formik.errors.last_name}
                />

                <InputField
                  required={true}
                  className="col-6"
                  name="mobile_number"
                  label="Mobile number"
                  type="text"
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                    const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                    formik.setFieldValue('mobile_number', trimmedValue);
                  }}
                  value={formik.values.mobile_number}
                  error={formik?.touched.mobile_number && formik.errors.mobile_number && formik.errors.mobile_number}
                />

                <InputField
                  name="email"
                  className="col-6"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik?.touched.email && formik.errors.email && formik.errors.email}
                />

                <InputField
                  label="Company"
                  className="col-4"
                  name="company_name"
                  onChange={formik.handleChange}
                  value={formik.values.company_name}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <div className="card-header">
              <strong>Address details</strong>
            </div>

            <div className="card-body">
              <div className="row">

                <InputField
                  name="address1"
                  className="col-4"
                  label="Address 1"
                  onChange={formik.handleChange}
                  value={formik.values.address1}
                />

                <InputField
                  name="address2"
                  className="col-4"
                  label="Address 2"
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                />

                <InputField
                  type="text"
                  name="postcode"
                  label="Postcode"
                  className="col-4"
                  onChange={formik.handleChange}
                  value={formik.values.postcode}
                />

                <InputField
                  name="city"
                  className="col-4"
                  label="Town/city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />

                <InputField
                  name="state"
                  label="State"
                  className="col-4"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                />

              </div>
            </div>
          </div>
        </div>

        <footer className="row my-3">
          <div className="col-12 d-flex gap-3">
            <Button name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading} />
            <LinkButton
              path={routes.customers}
              label="Cancel"
              className="link text-danger" />
          </div>
        </footer>
      </form>
    </>
  )
}

export default CreateCustomer



{/* <div className="modal fade" id="addCustomerModal" tabIndex={-1} aria-labelledby="addCustomerModalLabel" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered">
  <form className="modal-content" onSubmit={formik.handleSubmit}>
    <div className="modal-header">
      <h4 className="mb-0">{id ? 'Update customer' : 'New customer'}</h4>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div className="modal-body p-4">
      <div className="row">
        <InputField
          name="first_name"
          className="col-6"
          label="First name"
          required={true}
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik?.touched.first_name && formik.errors.first_name && formik.errors.first_name}
        />

        <InputField
          name="last_name"
          className="col-6"
          label="Last name"
          required={true}
          onChange={formik.handleChange}
          value={formik.values.last_name}
          error={formik?.touched.last_name && formik.errors.last_name && formik.errors.last_name}
        />

        <InputField
          required={true}
          className="col-6"
          name="mobile_number"
          label="Mobile number"
          type="text"
          onChange={(e: any) => {
            const inputValue = e.target.value;
            const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
            const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
            formik.setFieldValue('mobile_number', trimmedValue);
          }}
          value={formik.values.mobile_number}
          error={formik?.touched.mobile_number && formik.errors.mobile_number && formik.errors.mobile_number}
        />

        <InputField
          name="email"
          className="col-6"
          label="Email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik?.touched.email && formik.errors.email && formik.errors.email}
        />
        <h4>Address</h4>

        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-billingAddress" role="tabpanel" aria-labelledby="pills-billingAddress-tab" tabIndex={0}>
            <div className="row">
              <InputField
                name="full_name"
                label="Full name"
                className="col-4"
                onChange={formik.handleChange}
                value={formik.values.full_name}
              />

              <InputField
                className="col-4"
                label="Mobile number"
                name="alternate_number"
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                  const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                  formik.setFieldValue('alternate_number', trimmedValue);
                }}
                value={formik.values.alternate_number}
              />

              <InputField
                type="email"
                label="Email"
                name="email"
                className="col-4"
                onChange={formik.handleChange}
                value={formik.values.email}
              />

              <InputField
                label="Company"
                className="col-4"
                name="company_name"
                onChange={formik.handleChange}
                value={formik.values.company_name}
              />

              <InputField
                name="address1"
                className="col-4"
                label="Address 1"
                onChange={formik.handleChange}
                value={formik.values.address1}
              />

              <InputField
                name="address2"
                className="col-4"
                label="Address 2"
                onChange={formik.handleChange}
                value={formik.values.address2}
              />

              {/* <InputField
                className="col-4"
                label="Country"
              /> */}

//     <InputField
//       type="number"
//       name="postcode"
//       label="Postcode"
//       className="col-4"
//       onChange={formik.handleChange}
//       value={formik.values.postcode}
//     />

//     <InputField
//       name="city"
//       className="col-4"
//       label="Town/city"
//       onChange={formik.handleChange}
//       value={formik.values.city}
//     />

//     <InputField
//       name="state"
//       label="State"
//       className="col-4"
//       onChange={formik.handleChange}
//       value={formik.values.state}
//     />
//   </div>
// </div>

// <div className="tab-pane fade" id="pills-shippingAddress" role="tabpanel" aria-labelledby="pills-shippingAddress-tab" tabIndex={0}>
//   <div className="row">
//     <InputField
//       className="col-4"
//       label="Full name"
//     />

//     <InputField
//       className="col-4"
//       label="Phone"
//     />

//     <InputField
//       className="col-4"
//       type="email"
//       label="Email"
//     />

//     <InputField
//       className="col-4"
//       label="Company"
//     />

//     <InputField
//       className="col-4"
//       label="Address 1"
//     />

//     <InputField
//       className="col-4"
//       label="Address 2"
//     />

//     {/* <InputField
//       className="col-4"
//       label="Country"
//     /> */}

//     <InputField
//       className="col-4"
//       type="number"
//       label="Postcode"
//     />

//     <InputField
//       className="col-4"
//       label="Town/city"
//     />

//     <InputField
//       className="col-4"
//       label="State"
//     />
//   </div>
// </div>
//     </div>
//   </div>
// </div>

//     <div className="modal-footer">
//       <LinkButton path={`${routes.customers}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger" />
//       <Button type="submit" className="primary" name={id ? 'Update' : 'Submit'} loading={loading} />
//     </div>
//   </form>
// </div>
// </div> */}