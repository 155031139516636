export const currenciesArray = [
    { label: 'USD', value: '$' },
    { label: 'EUR', value: '€' },
    { label: 'GBP', value: '£' },
    { label: 'JPY', value: '¥' },
    { label: 'AUD', value: 'A$' },
    { label: 'CAD', value: 'C$' },
    { label: 'CHF', value: 'CHF' },
    { label: 'CNY', value: '¥' },
    { label: 'INR', value: '₹' },
    { label: 'RUB', value: '₽' },
    { label: 'BRL', value: 'R$' },
    { label: 'ZAR', value: 'R' },
    { label: 'MXN', value: '$' },
    { label: 'KRW', value: '₩' },
    { label: 'TRY', value: '₺' },
    { label: 'SGD', value: 'S$' },
    { label: 'HKD', value: 'HK$' },
    { label: 'NZD', value: 'NZ$' },
    { label: 'AED', value: 'د.إ' },
    { label: 'SEK', value: 'kr' },
    { label: 'NOK', value: 'kr' },
    { label: 'DKK', value: 'kr' },
    { label: 'PLN', value: 'zł' }
];