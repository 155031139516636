import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { inventoryService, locationService, productService, userService } from 'services'
import { routes, doDateFormatWithTime, serialNumber, strings, handleFilters, getSortIcon, getStatusLabel } from 'utils';
import { _Object } from 'utils/interfaces';

import { Button, SearchField, LinkButton, Pagination, Breadcrumbs, SEOHeader, ActiveFilters, TabsHeader } from 'views/components';

const InventoyList = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState<_Object>([])

  const [filters, setFilters] = useState<_Object>({
    users: [],
    locations: [],
    products: []
  })
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})

  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      status: '',
      order_id: '',
      product_id: '',
      handled_by: '',
      location_id: '',
    }
  })

  const handleCallApi = () => {
    setLoading(true);
    inventoryService.getinventory({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setInventories(data)
      setLoading(false);
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi();
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    locationService.getLocations('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        locations: data?.items
      }))
    })

    productService.getProducts('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        products: data?.items
      }))
    })

    userService.getUsers('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        users: data?.items
      }))
    })
  }, [])

  const deleteItem = (idToDelete: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading(true)
      inventoryService.deleteInventory(idToDelete).then(() => {
        inventoryService.getinventory(filterData).then((data: _Object) => {
          setInventories(data)
          setLoading(false)
        })
      });
    }
  };
console.log('filters',filters);

  return (
    <>
      <SEOHeader title="Inventory" />
      <Breadcrumbs
        trails={[
          {
            label: t('inventory.inventory'),
            path: ''
          }
        ]}
      />
      <TabsHeader />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <span className="d-flex align-items-center gap-3">
          {/* <Link
            to="#"
            className="primary text-decoration-none"
            data-bs-toggle="modal" data-bs-target="#export-report"
          >
            {t('common.export_report')}
          </Link> */}
          <LinkButton className="btn btn-primary" path={routes.createInventory} label={t('inventory.add')}>
            <i className="fa-solid fa-plus text-white"></i>
          </LinkButton>
        </span>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th>#</th>
                <th>{t('product.product')}</th>
                <th>
                <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-quantity' ? 'quantity' : '-quantity', setFilterData) }}>
                    {t('common.quantity')}
                    {getSortIcon(filterData.sort.includes('quantity') ? filterData.sort : '')}
                  </button>

                </th>
                <th>{t('location.location')}</th>

                <th>{t('order.order')} #</th>

                <th>
                <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-status' ? 'status' : '-status', setFilterData) }}>
                    {t('common.status')}
                    {getSortIcon(filterData.sort.includes('status') ? filterData.sort : '')}
                  </button>
                </th>

                <th>{t('common.note')}</th>

                <th>{t('user.user')}</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                {/* <th style={{ width: '12rem' }} className="date">
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th> */}

                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {inventories?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, inventories?.pagination?.current_page, i)}</td>
                    <td className="break-spaces">
                      <LinkButton path={`${routes.products}`} label={`${item?.product?.name}`} className="link p-0 text-dark fw-normal" />
                      <small>{t('common.sku')}: {item?.product?.sku}</small>
                    </td>

                    <td>{item?.quantity}</td>
                    <td>
                      <LinkButton path={`${routes.locations}`} label={`${item?.location?.name || '-'}`} className="link p-0 text-dark fw-normal" />
                      <small>{item?.sub_location?.name ?? '-'}</small>
                    </td>
                    <td>{item.order?.order_number || '-'}</td>
                    <td>{getStatusLabel(item?.status)}</td>
                    <td className="break-spaces">{item.description?.length > 0 ? item.description : '-'}</td>
                    <td>
                      <LinkButton path={`${routes.users}`} label={`${item?.user?.first_name} ${item?.user?.last_name}`} className="link p-0 text-dark fw-normal" />
                    </td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    {/* <td>{doDateFormatWithTime(item.updated_at)}</td> */}
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Button
                            className="link text-danger p-0"
                            onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )
              })}
              {loading === false && inventories?.items?.length === 0 && <tr>
                <td colSpan={10} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {inventories?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={inventories?.pagination?.current_page}
          per_page={inventories?.pagination?.per_page}
          total_pages={inventories?.pagination?.total_pages}
          total_items={inventories?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

      {/* <ReportExport
        title="Export report inventory"
        type="inventory"
      /> */}
    </>
  )
}
export default InventoyList
