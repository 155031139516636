import React, { useEffect, useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';
import { categoryService } from 'services';
import { routes, serialNumber, doDateFormatWithTime, closeModal, handleFilters, getSortIcon, strings } from 'utils';
import { _Object } from 'utils/interfaces';
import { Button, InputField, SearchField, LinkButton, Pagination, TabsHeader, TextArea, Breadcrumbs, SEOHeader } from 'views/components';
import { useTranslation } from 'react-i18next';

const Categories = () => {
  const { t } = useTranslation()

  const [id, setId] = useState('')
  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });

  const [categories, setCategories] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    sort: '-created_at',
    per_page: process.env.REACT_APP_PER_PAGE,
  })

  const [initialData, setInitialData] = useState<_Object>({
    name: '',
    description: ''
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    categoryService.getCategories({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setCategories(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    if (id) {
      setLoading({ edit: true })
      categoryService.getCategoryDetails(id).then((data) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Title').required(t('category.title_required')),
    }),

    onSubmit: (value) => {
      console.log('value>>>>>>>>>>>', value);

      setLoading({ update: true });

      if (id) {
        delete value.id,
          delete value.created_at
        delete value.parent
        delete value.client_id
        delete value.updated_at

        categoryService.updateCategory(id, value).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCategoriesModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })

      } else {
        categoryService.createCategory(value).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCategoriesModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      }
    }
  })

  // const deleteItem = (idToDelete: string) => {
  //   if (window.confirm('Are you sure to delete this item?')) {
  //     setLoading({ listing: true })
  //     categoryService.deleteCategory(idToDelete).then(() => {
  //       handleCallApi();
  //       setLoading({ listing: false })
  //     });
  //   }
  // };


  return (
    <>
      <SEOHeader title="Categories" />
      <Breadcrumbs
        trails={[
          {
            label: 'Categories',
            path: ''
          }
        ]}
      />

      <TabsHeader />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        <button type="button" className="btn btn-primary" onClick={() => {
          formik.resetForm(); setId(''); setInitialData({
            name: '',
            description: ''
          })
        }} data-bs-toggle="modal" data-bs-target="#addCategoriesModal">
          <i className="fa-solid fa-plus text-white"></i>
          {t('category.add_category')}
        </button>

        {/* Modal */}
        <div className="modal fade" id="addCategoriesModal" tabIndex={-1} aria-labelledby="addCategoriesModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="mb-0">{id ? t('category.update_category') : t('category.new_category')}</h4>
                <button type="button" onClick={() => {
                  setId(''), setInitialData({
                    name: '',
                    description: ''
                  })
                }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <InputField
                  name="name"
                  required={true}
                  label={t('common.title')}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik?.touched.name && formik.errors.name && formik.errors.name}
                />

                <TextArea
                  rows={5}
                  name="description"
                  label={t('common.description')}
                  onChange={formik.handleChange}
                  placeholder={t('common.enter_description')}
                  value={formik.values.description}
                  error={formik.touched.description && formik.errors.description}
                />
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.categories}`} label={t('common.cancel')} data-bs-dismiss="modal" className="link text-danger" />
                <Button name={id ? t('common.update') : t('common.submit')} className="primary" loading={loading.update} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped order-listing">
            <thead >
              <tr className="bg-white">
                <th>#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                    {t('common.title')}
                    {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                  </button>
                </th>

                <th>{t('common.description')}</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    {t('common.updateDate')}
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {categories?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, categories?.pagination?.current_page, i)}</td>
                    <td>{item.name}</td>
                    <td className="break-spaces">{item.description || '-'}</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id)} type="button" id="addCategoriesModal" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addCategoriesModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        {/* <li className="list-inline-item">

                          <Button
                            type="submit"
                            className="link text-dark p-0"
                            onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                )
              })}

              {loading.listing === false && categories?.items?.length === 0 &&
                <tr>
                  <td colSpan={6} className="text-center no-entry">
                    <aside>
                      <i className="fa-solid fa-ban"></i>
                      {strings.noRecordFound}
                    </aside>
                  </td>
                </tr>
              }
            </tbody>

          </table>
        </div>
      </div>

      {categories?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={categories?.pagination?.current_page}
          per_page={categories?.pagination?.per_page}
          total_pages={categories?.pagination?.total_pages}
          total_items={categories?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default Categories
