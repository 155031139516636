import React, { useEffect, useState } from 'react';

import { routes, serialNumber, doDateFormatWithTime, strings, generateFormattedAddress, handleFilters, getSortIcon, capitalize } from 'utils';
import { _Object } from 'utils/interfaces';
import { supplierService } from 'services';
import { Button, SearchField, LinkButton, Pagination, SelectField, TabsHeader, Breadcrumbs, SEOHeader, ActiveFilters } from 'views/components';
// import { AsyncSelectField } from 'views/components/form-inputs';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const Suppliers = () => {
  const { t } = useTranslation()

  // const selectAsyncSelect: any = useRef([])
  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [suppliers, setSuppliers] = useState<_Object>([])
  // const [brands, setBrands] = useState<_Object>([])
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})
  const [filterData, setFilterData] = useState<_Object>({
    sort: '-created_at',
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    filter: {
      city: '',
      status: '',
      brand_id: '',
      payment_mode: '',
    }
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    supplierService.getSuppliers({ ...filterData, q: formik.values.q }).then((data: _Object) => {
      setSuppliers(data)
      setLoading({ listing: false });
    })
  }

  const formik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (formik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (formik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, formik.values.q])

  // useEffect(() => {
  //   brandService.getBrands(filterData).then((data: _Object) => {
  //     setBrands(data.items)
  //   })
  // }, [])

  const deleteItem = async (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      await supplierService.deleteSupplier(id)
      const supplierData = suppliers.items.filter((data: _Object) => {
        return data.id !== id;
      })
      setSuppliers((prev: _Object) => ({ ...prev, items: supplierData }))
      setLoading({ listing: false })
    }
  }

  // const brandOption = async (brandValue: string) => {
  //   const data: _Object = await brandService.getBrands({ q: brandValue })
  //   return data.items?.map((item: _Object) => { return { label: capitalize(item.name), value: item.id } })
  // }

  return (
    <>
      <SEOHeader title="Suppliers" />
      <Breadcrumbs
        trails={[
          {
            label: t('supplier.suppliers'),
            path: ''
          }
        ]}
      />

      <TabsHeader />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={formik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              disabled={!formik.values.q}
              className="mb-md-0"
              value={formik.values.q}
              onChange={(e: _Object) => {
                formik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}
        <LinkButton className="primary" path={`${routes.createSuppiler}`} label="">
          <i className="fa-solid fa-plus text-white"></i>
          {t('supplier.add_supplier')}
        </LinkButton>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card border-0 my-3">
            <div className="table-responsive">
              <table className="card-body mb-0 table table-borderless table-striped order-listing">
                <thead>
                  <tr className="bg-white">
                    <th>#</th>
                    <th>
                      <button
                        className="btn p-0 border-0"
                        onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                        {t('common.title')}
                        {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                      </button>
                    </th>
                    <th>{t('supplier.contact')}</th>
                    <th>{t('common.address')}</th>

                    <th>
                      <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.status?.length > 0 ? 'show filter-add' : 'show'}`}>
                        {t('common.status')}
                      </button>

                      <div className="dropdown">
                        <div className="dropdown-menu dropdown-menu-end">
                          <SelectField
                            menuIsOpen={true}
                            isSearchableHide={true}
                            options={[
                              { label: t('common.active'), value: 'active' },
                              { label: t('common.delete'), value: 'deleted' },
                              { label: t('common.completed'), value: 'completed' },
                            ]}
                            onChange={(e: _Object) => {
                              setFilterData((prev) => ({ ...prev, filter: { ...prev.filter, status: e?.value || '' } }));
                              setFilteredLabel((prevLabel) => ({
                                filter: {
                                  ...prevLabel.filter,
                                  status: e?.label || '',
                                },
                              }));
                            }}
                            getOptionLabel={(option: _Object) => option.label}
                            getOptionValue={(option: _Object) => option.value}
                            isClearable
                          />
                        </div>
                      </div>
                    </th>

                    {/* <th>
                      <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.brand_id?.length > 0 ? 'show filter-add' : 'show'}`}
                      >
                        {t('supplier.brand')}
                      </button>
                      <div className="dropdown">
                        <div className="dropdown-menu dropdown-menu-end">
                          <AsyncSelectField
                            isClearable
                            cacheOptions
                            name="brand_id"
                            menuIsOpen={true}
                            loadOptions={brandOption}
                            ref={(ref: any) => (selectAsyncSelect.current = ref)}
                            defaultOptions={brands?.map((item: _Object) => {
                              return {
                                label: capitalize(item.name), value: item.id
                              }
                            })}
                            onChange={(val: _Object) => {
                              setFilterData((e) => ({ ...e, filter: { ...e.filter, brand_id: val?.value || '' } }));
                              setFilteredLabel((prevLabel) => ({
                                filter: {
                                  ...prevLabel.filter,
                                  brand_id: val?.label || '',
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </th> */}

                    <th>
                      <button
                        className="btn p-0 border-0"
                        onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                        {t('common.createDate')}
                        {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                      </button>
                    </th>

                    <th>
                      <button
                        className="btn p-0 border-0"
                        onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                        {t('common.updateDate')}
                        {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                      </button>
                    </th>

                    <th className="action">{t('common.action')}</th>
                  </tr>
                </thead>

                <tbody>
                  {suppliers?.items?.map((item: _Object, i: number) => {

                    return (
                      <tr className="align-middle" key={i}>
                        <td>{serialNumber(filterData.per_page, suppliers?.pagination?.current_page, i)}</td>
                        <td className="break-spaces">{capitalize(item.name)}<br />
                          <small>
                            {capitalize(item.company_name)}
                          </small>
                        </td>
                        <td>
                          {item.mobile_number || item.email ? (
                            <>
                              {item.mobile_number}<br />
                              <small>{item.email}</small>
                            </>
                          ) : (
                            '-'
                          )}
                        </td>

                        <td className="break-spaces">{generateFormattedAddress(item)}
                        </td>

                        <td>{capitalize(item?.status)}</td>

                        {/* <td>
                        {item?.brands?.map((brand: _Object, i: number) => {
                          return (
                            <span key={brand.id}>{brand?.name ? brand?.name + ((item?.brands?.length - 1) !== i ? ', ' : '') : '-'}</span>
                          )
                        })}
                      </td> */}

                        {/* <td className="break-spaces">
                          {item?.brands?.length > 0
                            ? item.brands.map((brand: _Object, i: number) => (
                              <span key={brand.id}>
                                {brand?.name || '-'}
                                {i !== item.brands.length - 1 && ', '}
                              </span>
                            ))
                            : '-'}
                        </td> */}

                        <td>{doDateFormatWithTime(item.created_at)}</td>
                        <td>{doDateFormatWithTime(item.updated_at)}</td>
                        <td>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <LinkButton
                                path={`${routes.suppilers}/edit/${item.id}`}
                                label={''}
                                className="link text-dark p-0">
                                <i className="fa fa-pen"></i>
                              </LinkButton>
                            </li>
                            <li className="list-inline-item">
                              <Button
                                type="submit"
                                className="link text-dark p-0"
                                onClick={() => deleteItem(item.id)}>
                                <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                              </Button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    )
                  })}

                  {loading.listing === false && suppliers?.items?.length === 0 && <tr>
                    <td colSpan={9} className="text-center no-entry">
                      <aside>
                        <i className="fa-solid fa-ban"></i>
                        {strings.noRecordFound}
                      </aside>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {suppliers?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={suppliers?.pagination?.current_page}
          per_page={suppliers?.pagination?.per_page}
          total_pages={suppliers?.pagination?.total_pages}
          total_items={suppliers?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>

  )
}
export default Suppliers
