import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { _Object } from 'utils/interfaces';
import { Breadcrumbs, Button, InputField, RadioButton, SelectField, SEOHeader } from 'views/components';
import { userService } from 'services';
import { countries } from 'utils/countries';
import { setLoggedInUser } from 'redux/slices/session.slice';
import { AppDispatch } from 'redux/store';
import { currenciesArray } from 'utils/currencies';
import { paymentMethod } from 'utils/service';

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>()

  const { me } = useSelector((state: _Object) => state.session);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [clientId, setClientId] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number1: '',
      gst: '',
      address1: '',
      address2: '',
      city: '',
      country: 'IN',
      payment_methods: '',
      province: '',
      enable_receipt_print: false,
      enable_tax: '',
      tax_cal_method: 'including',
      postcode: '',
      enable_discount: false
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required().label('Store name'),
      address1: Yup.string().required().label('Address1'),
      address2: Yup.string().required().label('Address2'),
      tax_cal_method: Yup.string().required().label('Tax method'),
      city: Yup.string().required().label('City'),
      payment_methods: Yup.array()
        .min(1, 'At least one payment method is required')
        .of(Yup.string().required('Each payment method must be selected'))
        .required('Payment methods are required')
        .label('Payment Methods'),
      currency: Yup.string().required().label('Currency'),
      phone_number1: Yup.string()
        .required()
        .label('Mobile number')
        .min(10, 'Mobile number must be at least 10 characters'),
      // gst: Yup.string()
      //   .required()
      //   .min(15, 'GST number should be 15 characters')
      //   .max(15, 'GST number should be 15 characters'),
      email: Yup.string().required().label('Email').email('Please enter a valid email'),
      enable_receipt_print: Yup.string().required().label('Printer disabled'),
    }),
    onSubmit: (values: _Object) => {
      setLoading(true);
      userService.updateClient(clientId, values).then((response: _Object) => {
        setLoading(false);
        if (!response.error) {
          getClient()
          // formik.resetForm();
        }
      }).catch(() => {
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    if (me.client_id) {
      getClient()
    }
  }, [me.client_id]);

  const getClient = () => {
    setPageLoading(true);
    userService.getClient(me.client_id).then((data: _Object) => {
      setClientId(data.id)
      if (data) {
        formik.setValues({
          name: data.name || '',
          email: data.email || '',
          phone_number1: data.phone_number1 || '',
          gst: data.gst || '',
          address1: data.address1 || '',
          address2: data.address2 || '',
          enable_receipt_print: data.enable_receipt_print || false,
          enable_tax: data.enable_tax || false,
          tax_cal_method: data.tax_cal_method || '',
          status: data.status || '',
          city: data.city || '',
          country: data.country || 'IN',
          province: data.province || '',
          postcode: data.postcode || '',
          enable_discount: data.enable_discount || false,
          payment_methods: data.payment_methods || '',
          currency: data.currency || 'INR',
        });
      }
      setPageLoading(false);
      dispatch(setLoggedInUser())
    }).catch(() => {
      setPageLoading(false);
    });
  }

  return (
    <>
      <SEOHeader title="Settings" />
      <Breadcrumbs
        trails={[
          {
            label: t('common.settings'),
            path: ''
          }
        ]}
      />

      <form className={`row row-cols-1 row-cols-md-2 row-cols-lg-2 mb-3 ${pageLoading === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
        <div className="col mb-md-3 mb-lg-0">
          <div className="card h-100">
            <div className="card-header p-3">
              <h4 className="mb-0">
                {t('store.details')}
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <InputField
                  required={true}
                  label={t('store.name')}
                  name="name"
                  className="col-12 col-lg-6"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik?.touched.name && formik.errors.name}
                />
                <InputField
                  // required={true}
                  name="gst"
                  label={t('store.gstin')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.gst}
                  onBlur={formik.handleBlur}
                  error={formik?.touched.gst && formik.errors.gst}
                />

                <InputField
                  required={true}
                  type="email"
                  label={t('common.email')}
                  name="email"
                  className="col-12 col-lg-6"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik?.touched.email && formik.errors.email}
                />
                <InputField
                  required={true}
                  type="number"
                  name="phone_number1"
                  label={t('common.mobile_number')}
                  className="col-12 col-lg-6"
                  value={formik.values.phone_number1}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                    const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                    formik.setFieldValue('phone_number1', trimmedValue);
                  }}
                  error={formik?.touched.phone_number1 && formik.errors.phone_number1 && formik.errors.phone_number1}
                />
                <InputField
                  required={true}
                  name="address1"
                  label={t('Address1')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.address1}
                  error={formik?.touched.address1 && formik.errors.address1}
                />
                <InputField
                  required={true}
                  name="address2"
                  label={t('Address2')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                  error={formik?.touched.address2 && formik.errors.address2}
                />
                <InputField
                  required={true}
                  name="city"
                  label={t('City')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  error={formik?.touched.city && formik.errors.city}
                />
                <InputField
                  className="col-6"
                  // type="number"
                  name="postcode"
                  label={t('common.postCode')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.postcode}
                />

                <SelectField
                  className="col-6"
                  name="country"
                  label={t('common.country')}
                  options={countries}
                  value={{ value: formik.values.country || 'IN' }}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('country', e?.value || '')
                  }}
                />

                <SelectField
                  className="col-6"
                  name="province"
                  label={t('common.state')}
                  options={countries?.find((obj: _Object) => obj.value === (formik?.values?.country?.length > 0 ? formik?.values?.country : 'IN'))?.states}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('province', e?.label || '')
                  }}
                  value={{ label: formik.values.province }}
                  // disabled={!formik.values.country}
                  getOptionLabel={(option: { [key: string]: string }) => option?.label}
                  getOptionValue={(option: { [key: string]: string }) => option?.label}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card h-100">
            <div className="card-header p-3">
              <h4 className="mb-0">
                Other
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <RadioButton
                  required={true}
                  name="enable_tax"
                  className="col-5"
                  label={'Enable tax'}
                  value={formik.values.enable_tax}
                  options={[
                    { label: t('common.yes'), value: true },
                    { label: t('common.no'), value: false },
                  ]}
                  onChange={(e: any) => {
                    const value = e.target.value === 'true';
                    formik.setFieldValue('enable_tax', value);
                  }}
                  error={formik?.touched.enable_tax && formik.errors.enable_tax}
                />

                <RadioButton
                  required={true}
                  name="tax_cal_method"
                  className="col-5"
                  label={'How you enter product price?'}
                  value={formik.values.tax_cal_method}
                  options={[
                    { label: 'Price with Tax', value: 'including' },
                    { label: 'Price without Tax', value: 'excluding' },
                  ]}
                  onChange={formik.handleChange}
                  error={formik?.touched.tax_cal_method && formik.errors.tax_cal_method}
                />

                <RadioButton
                  required={true}
                  name="enable_receipt_print"
                  className="col-5"
                  label={'Print receipt'}
                  value={formik.values.enable_receipt_print}
                  options={[
                    { label: t('common.yes'), value: true },
                    { label: t('common.no'), value: false },
                  ]}
                  onChange={(e: any) => {
                    const value = e.target.value === 'true';
                    formik.setFieldValue('enable_receipt_print', value);
                  }}
                  error={formik?.touched.enable_receipt_print && formik.errors.enable_receipt_print}
                />

                <RadioButton
                  required={true}
                  name="enable_discount"
                  className="col-5"
                  label={'Enable Discount'}
                  value={formik.values.enable_discount}
                  options={[
                    { label: t('common.yes'), value: true },
                    { label: t('common.no'), value: false },
                  ]}
                  onChange={(e: any) => {
                    const value = e.target.value === 'true';
                    formik.setFieldValue('enable_discount', value);
                  }}
                  error={formik?.touched.enable_discount && formik.errors.enable_discount}
                />

                <SelectField
                  className="col-6"
                  name="currency"
                  label={t('common.currency')}
                  required={true}
                  options={currenciesArray}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: { label: string, value: string }) => `${option?.label} - ${option?.value}`}
                  value={{ label: formik.values.currency || 'INR' }}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('currency', e?.label || '')
                  }}
                  error={formik.values.status === '' && formik.touched.status && formik.errors.status}
                />

                <SelectField
                  name="payment_methods"
                  className="col-6"
                  label="Payment Methods"
                  required={true}
                  onBlur={formik.handleBlur}
                  options={paymentMethod}
                  value={paymentMethod?.filter((obj: { [key: string]: string, }) => formik.values.payment_methods?.includes(obj.value))}
                  onChange={(val: _Object) => {
                    formik.setFieldValue('payment_methods', val.map((data: { [key: string]: string }) => { return (data.value) }))
                  }}
                  error={formik.values.payment_methods === '' && formik.touched.payment_methods && formik.errors.payment_methods}
                  isMulti
                />

              </div>
            </div>
          </div>
        </div>

        {/* <div className="col">
          <div className="card h-100">
            <div className="card-header p-3">
              <h4 className="mb-0">
                {t('store.printer_details')}
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <RadioButton
                  required={true}
                  name="enable_receipt_print"
                  className="col-5"
                  label={t('store.printer_disabled')}
                  value={formik.values.enable_receipt_print}
                  options={[
                    { label: t('common.yes'), value: 'true' },
                    { label: t('common.no'), value: 'false' },
                  ]}
                  onChange={formik.handleChange}
                  error={formik?.touched.enable_receipt_print && formik.errors.enable_receipt_print}
                />
                <SelectField
                  isClearable
                  label={t('store.receipt_size')}
                  // required={true}
                  className="col-7"
                  name="receipt_size"
                  options={[
                    { label: '2 Inch', value: '2' },
                    { label: '3 Inch', value: '3' },
                  ]}
                  value={{ value: formik?.values?.receipt_size }}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('receipt_size', e?.value || null)
                  }}
                  getOptionLabel={(option: _Object) => option?.label}
                  getOptionValue={(option: _Object) => option?.value}
                // error={formik?.touched.receipt_size && formik.errors.receipt_size}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row" style={{ width: '100%' }}>
          <div className="col-12 gap-3 mt-3">
            <Button name={t('common.submit')} loading={loading} className="primary" />
          </div>
        </div>
      </form>

    </>
  )
}
export default Settings