import React, { useEffect, useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { brandService } from 'services';
import { closeModal, doDateFormatWithTime, getSortIcon, handleFilters, routes, serialNumber, strings } from 'utils';
import { _Object } from 'utils/interfaces';
import { Button, InputField, SearchField, Pagination, TextArea, TabsHeader, LinkButton, Breadcrumbs, SEOHeader } from 'views/components';
import { useTranslation } from 'react-i18next';

const BrandsList = () => {
	const { t } = useTranslation()

  const [id, setId] = useState('')
  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [brands, setbrands] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })
  const [initialData, setInitialData] = useState<_Object>({
    name: '',
    description: ''
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    brandService.getBrands({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setbrands(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    if (id) {
      setLoading({ edit: true })
      brandService.getBrandDetails(id).then((data) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      name: Yup.string().label('Title').required(t('brand.title_required')),
    }),

    onSubmit: (values) => {
      setLoading({ update: true });

      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at

        brandService.updateBrand(id, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addBrandsModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })

      } else {
        // values.slug ? values.slug : delete values.slug
        brandService.createBrand(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addBrandsModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      }
    }
  })

  const deleteItem = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      brandService.deleteBrand(id).then(() => {
        handleCallApi();
        setLoading({ listing: false })
      })
    }
  }

  return (
    <>
      <SEOHeader title="Brands" />
      <Breadcrumbs
        trails={[
          {
            label: 'Brands',
            path: ''
          }
        ]}
      />

      <TabsHeader />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        <button type="button" onClick={() => {
          formik.resetForm(); setId('')
            ; setInitialData({
              name: '',
              description: '',
            })
        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addBrandsModal">
          <i className="fa-solid fa-plus text-white"></i>
          {t('brand.add_brand')}
        </button>

        {/* Modal */}
        <div className="modal fade" id="addBrandsModal" tabIndex={-1} aria-labelledby="addBrandsModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className="modal-content" onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <aside>
                  <h4 className="mb-0">{id ? t('brand.update_brand') : t('brand.new_brand')}</h4>
                </aside>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className={`modal-body ${loading.edit === true ? 'is-loading' : ''}`}>
                <div className="row filters">
                  <InputField
                    name="name"
                    required={true}
                    className="col"
                    label={t('common.title')}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik?.touched.name && formik.errors.name && formik.errors.name}
                  />

                  <TextArea
                    rows={5}
                    name="description"
                    className="col-12"
                    label={t('common.description')}
                    onChange={formik.handleChange}
                    placeholder={t('common.enter_description')}
                    value={formik.values.description}
                    error={formik.touched.description && formik.errors.description}
                  />
                </div>
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.brands}`} label={t('common.cancel')} data-bs-dismiss="modal" className="link text-danger" />
                <Button type="submit" className="primary" name={id ? t('common.update') : t('common.submit')} loading={loading.update} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped order-listing">
            <thead>
              <tr className="align-middle bg-white">
                <th>#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                    {t('common.title')}
                    {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                  </button>
                </th>
                <th>{t('common.description')}</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    {t('common.updateDate')}
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {brands?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, brands?.pagination?.current_page, i)}</td>
                    <td>{item.name}</td>
                    <td className="break-spaces">{item.description || '-'}</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id)} id="addBrandsModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addBrandsModal">
                            <i className="fa fa-pen"></i>
                          </button>

                        </li>
                        <li className="list-inline-item">
                          <Button
                            className="link text-dark p-0"
                            onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )
              })}
              {loading.listing === false && brands?.items?.length === 0 && <tr>
                <td colSpan={6} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {brands?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={brands?.pagination?.current_page}
          per_page={brands?.pagination?.per_page}
          total_pages={brands?.pagination?.total_pages}
          total_items={brands?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default BrandsList