import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'
import { _Object } from 'utils/interfaces'
import { userService } from 'services'
import { InputField, SelectField, Button, LinkButton, Breadcrumbs, SEOHeader, CheckBox } from 'views/components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const CreateUser = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { me } = useSelector((state: _Object) => state.session)

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [isEditMode, setEditMode] = useState(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    setEditMode(false)
    setInitialData({
      role: '',
      email: '',
      status: '',
      password: '',
      last_name: '',
      username: '',
      first_name: '',
      mobile_number: '',
      confirm_password: '',
    })

    setLoading({ listing: true });

    if (id) {
      setLoading({ edit: true })
      setEditMode(true)
      userService.getUserDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }
  }, [location])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().trim().label('First name').required(),
      password: id && isChecked
        ? Yup.string()
        : Yup.string()
          .label('Password')
          .required('Password is required')
          .min(8, 'Password must be at least 8 characters')
          .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
          .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
          .matches(/\d/, 'Password must contain at least one number')
          .matches(/[\W_]/, 'Password must contain at least one special character'),
      confirm_password: id && isChecked ? Yup.string() : Yup.string()
        .label('Confirm Password')
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Confirm password must match'),
      status: Yup.string().label('Status').required(),
      last_name: Yup.string().trim().label('Last name').required(),
      email: Yup.string().email('Invalid email format').required().label('Email'),
      username: Yup.string()
        .required('Username is required'),
      mobile_number: Yup.string()
        .label('Mobile number')
        .required()
        .min(10, 'Mobile number must be at least 10 characters'),
      role: Yup.string().label('Role').required(),
    }),
    onSubmit: (values) => {
      setLoading({ update: true });
      values.mobile_number = (values.mobile_number).toString()
      if (id) {
        delete values.client_id,
          delete values.id,
          delete values.password,
          delete values.token,
          delete values.location,
          delete values.created_at
        delete values.updated_at
        delete values.username
        delete values.confirm_password
        delete values.change_password

        userService.updateUser(id, values).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data.error === false) {
            navigate(`${routes.users}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      } else {
        delete values.confirm_password,
          userService.createUser(values).then((data: _Object) => {

            setLoading({ update: true, listing: true })
            if (data.error === false) {
              navigate(`${routes.users}`)
            } else {
              toast.error(data.message)
              setLoading({ update: false, listing: false });
              throw new Error(data.error);
            }
          }).catch((error) => {
            error && setLoading({ update: false, listing: false });
          })
      }
    }
  })
  useEffect(() => {
    setIsChecked(!formik?.values?.change_password)
  }, [formik])
  console.log('fffffffffffffff', formik.errors, formik?.values?.change_password);

  return (
    <>
      <SEOHeader title="Users" />
      <Breadcrumbs
        trails={[
          {
            label: t('user.users'),
            path: `${routes.users}`
          },
          {
            label: `${isEditMode ? t('user.update_user') : t('user.new_user')}`,
            path: ''
          },
        ]}
        pageTitle={`${isEditMode ? t('user.update_user') : t('user.new_user')}`}
      />

      <form className={`row filters ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit} >
        <div className="col">
          <div className="card">
            <div className="card-header">
              <strong>{t('user.accounts_details')}</strong>
            </div>

            <div className="card-body">
              <div className="row">
                <InputField
                  className="col-6"
                  name="first_name"
                  label={t('common.firstName')}
                  required={true}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.first_name && formik.errors.first_name}
                />

                <InputField
                  className="col-6"
                  name="last_name"
                  label={t('common.lastName')}
                  required={true}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.last_name && formik.errors.last_name}
                />

                <InputField
                  className="col-6"
                  type="text"
                  name="mobile_number"
                  label={t('common.mobile_number')}
                  required={true}
                  value={formik.values.mobile_number}
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');
                    const trimmedValue = numericValue.slice(0, 10);
                    formik.setFieldValue('mobile_number', trimmedValue);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.mobile_number && formik.errors.mobile_number}
                />

                <InputField
                  className="col-6"
                  type="email"
                  name="email"
                  label={t('common.email')}
                  required={true}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />

                <InputField
                  className={`col-${id ? '4' : '6'}`}
                  type="name"
                  required={true}
                  name="username"
                  label={t('common.username')}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.username && formik.errors.username}
                />

                {!id &&
                  <InputField
                    type="password"
                    name="password"
                    required={true}
                    label={t('common.password')}
                    className="col-6"
                    placeholder={t('common.password')}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                  />
                }

                <SelectField
                  className={`col-${id ? '4' : '6'}`}
                  name="role"
                  label={t('common.role')}
                  required={true}
                  options={me.role === 'super_admin' ? [
                    { label: 'Super admin', value: 'super_admin' },
                    { label: 'Administrator', value: 'admin' },
                    { label: 'Outlet manager', value: 'outlet_manager' },
                    { label: 'Salesman', value: 'salesman' },
                  ] : [
                    { label: 'Administrator', value: 'admin' },
                    { label: 'Outlet manager', value: 'outlet_manager' },
                    { label: 'Salesman', value: 'salesman' },
                  ]}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('role', e?.value || '')
                  }}
                  value={{ value: formik.values.role }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: _Object) => option.label}
                  getOptionValue={(option: _Object) => option.value}
                  error={formik.touched.role && formik.errors.role}
                  isClearable
                />

                <SelectField
                  isClearable
                  name="status"
                  label={t('common.status')}
                  required={true}
                  className={`col-${id ? '4' : '6'}`}
                  options={[
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Active', value: 'active' },
                  ]}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('status', e?.value || '')
                  }}
                  value={{ value: formik.values.status }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: _Object) => option.label}
                  getOptionValue={(option: _Object) => option.value}
                  error={formik.touched.status && formik.errors.status}
                />

                {id &&
                  <CheckBox
                    name="change_password"
                    required={true}
                    className="col-12"
                    label={''}
                    value={formik?.values?.change_password}
                    options={[
                      { label: t('common.change_password'), value: false },
                    ]}
                    onChange={() => {
                      formik.setFieldValue('change_password', !formik?.values?.change_password)
                      setInitialData(() => ({
                        ...formik.values,
                        change_password: !formik?.values?.change_password,
                      }))
                    }} />
                }

                {(id && formik?.values?.change_password) &&
                  <>
                    <InputField
                      type="password"
                      name="password"
                      required={true}
                      label={t('common.password')}
                      className="col-6"
                      placeholder={t('common.password')}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.errors.password}
                    />

                    <InputField
                      type="password"
                      name="confirm_password"
                      required={true}
                      className="col-6"
                      placeholder={t('common.password')}
                      label={t('common.confirm_password')}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.confirm_password}
                      error={formik.errors.confirm_password}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>

        <footer className="row my-3">
          <div className="col-12 d-flex gap-3">
            <Button type="submit" name={isEditMode ? t('common.update') : t('common.submit')} className="primary" loading={loading.update} />
            <LinkButton
              path={routes.users}
              label={t('common.cancel')}
              className="link text-danger fw-medium" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default CreateUser