import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { _Object } from 'utils/interfaces';
import { routes, generateAvatar, strings } from 'utils';
import Modal from './modal';
import { Button, LinkButton } from '../../../components';
import { SearchField } from 'views/components/form-inputs';
import { userService } from 'services';
import { setCustomer } from 'redux/slices/cart.slice';

const Customer = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  console.log(searchText);

  const location = useLocation();
  const [loading, setLoading] = useState<any>({
    search: false,
    btn: false,
    recent: false,
  });

  const { cart }: any = useSelector((state: _Object) => state.cart);
  const dispatch = useDispatch();

  const selectRef = useRef<any>(null);
  const [users, setUsers] = useState<_Object[]>([]);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (selectRef.current && selectRef.current.state && !selectRef.current.state.isFocused) {
      selectRef.current.focus();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      q: '',
    },
    enableReinitialize: true,
    onSubmit: (values: any) => {
      setIsFormSubmit(true);
      setLoading((prev: any) => ({ ...prev, search: true }));
      userService.getCustomerSearch({ q: values.q }).then((result: any) => {
        setUsers(result?.items || []);
        setLoading((prev: any) => ({ ...prev, search: false }));
      });
    },
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (users.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex === users.length - 1 ? 0 : prevIndex + 1
        );
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0 ? users.length - 1 : prevIndex - 1
        );
      } else if (e.key === 'Enter' && selectedIndex !== null) {
        e.preventDefault();
        const selectedCustomer = users[selectedIndex];
        dispatch(setCustomer(selectedCustomer));
      }
    }
  };

  const handleClick = () => {
    dispatch(setCustomer({}))
    setSelectedIndex(null)
    const getId = document.getElementById('customerSearchField')
    if (getId) {
      getId.focus()
    }
  }

  return (
    <div className="mt-3 customer-list">
      {(location.pathname === `${routes.sales}` && !cart.customer?.id) &&
        <div className="d-flex justify-content-between mb-3">
          <LinkButton path={`${routes.sales}`} className="light" label="">
            <i className="fa-solid fa-user m-0"></i>
          </LinkButton>

          <h5 className="my-auto">{t('customer.customers')}</h5>

          <Button className="light" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <i className="fa fa-plus me-0"></i>
          </Button>
          <Modal value={formik.values.q} setSearchText={setSearchText} />
        </div>
      }

      {(location.pathname === `${routes.sales}` && !cart.customer?.id) && (
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={handleKeyDown}
          className={`form-loading ${loading.search ? 'btn-loading' : ''}`}
        >
          <SearchField
            name="q"
            disabled={!formik.values.q}
            id="customerSearchField"
            autoFocus
            type="search"
            autoComplete="off"
            value={formik.values.q}
            onChange={(e: any) => {
              formik.handleChange(e);
              setIsFormSubmit(false);
            }}
          />
          <ul className="list-group">
            {users?.map((data: any, i: number) => {
              const generateAvatar = (firstName: string, lastName: string) => {
                const capitalize = (name: string) =>
                  name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase();
                const firstInitial = capitalize(firstName)[0];
                const lastInitial = capitalize(lastName)[0];
                return `${firstInitial}${lastInitial}`;
              };

              const avatar = generateAvatar(data?.first_name, data?.last_name);
              return (
                <li
                  key={i}
                  className={`list-group-item ${selectedIndex === i ? 'active' : ''}`}
                  aria-current="true"
                  onClick={() => dispatch(setCustomer(data))}
                >
                  <div className="d-flex">
                    <div className="rounded-circle bg-light my-auto avtar">
                      <span className="mb-0">{avatar}</span>
                    </div>

                    <aside className="ms-2">
                      <span>{data?.full_name}</span>
                      <h6 className="m-0">{data.mobile_number}</h6>
                    </aside>

                  </div>
                </li>
              );
            })}
          </ul>
        </form>
      )}

      {cart.customer && (
        <ul className="list-group list-group-flush mt-3 overflow-auto">
          {!cart.customer.id ? (
            <>
              {(formik.values.q && users?.length === 0 && isFormSubmit && !loading.search) && (
                <div>
                  <p className="text-danger text-center">{strings.noRecordFound}</p>
                </div>
              )}
            </>
          ) : (
            <>
              <LinkButton
                label=""
                className="light d-flex justify-content-between align-items-center w-100"
                onClick={handleClick}
              >
                <div className="bg-white rounded-circle avtar">
                  <i className="fa-solid fa-user-plus m-0"></i>
                </div>
                <h5 className="mb-0 text-capitalize">
                  {cart?.customer?.id ? `${generateAvatar(cart?.customer?.first_name, cart?.customer?.last_name)}` : t('customer.add_customer')}
                </h5>
                <i className="fa fa-chevron-right me-0"></i>
              </LinkButton>
            </>
          )}
        </ul>
      )
      }
    </div >
  );
};

export default Customer;
