import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { _Object } from 'utils/interfaces';

import { Button } from 'views/components';
import { setPrintDevice } from 'redux/slices/session.slice';

const PrinterAccess = () => {

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { printerDevice, me } = useSelector((state: _Object) => state.session)

	const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		(async () => {
			const existingDevices = await (navigator as any).usb.getDevices();
			const device = existingDevices.filter((item: any) => (item.serialNumber === 'Printer' || item?.serialNumber === '_201907025'))

			if (device.length > 0) {
				for (const device of existingDevices) {
					if ((device.serialNumber === 'Printer' || device?.serialNumber === '_201907025')) {
						dispatch(setPrintDevice(device));
					}
				}
			} else {
				me.client?.enable_receipt_print && setOpenModal(true);
			}
		})();
	}, [me, printerDevice]);

	const requestPrinterDevice = async () => {

		try {
			const existingDevices = await (navigator as any).usb.getDevices();
			existingDevices.map((item: any) => {

				console.log('dddddddddd', item);
			})
			const device = existingDevices.filter((item: any) => (item?.serialNumber === 'Printer' || item?.serialNumber === '_201907025'))

			if (device.length > 0) {
				for (const device of existingDevices) {
					if ((device.serialNumber === 'Printer' || device?.serialNumber === '_201907025')) {
						dispatch(setPrintDevice(device))
					}
				}
			} else {
				const device = await (navigator as any).usb?.requestDevice({ filters: [] });
				if ((device.serialNumber === 'Printer' || device?.serialNumber === '_201907025')) {
					dispatch(setPrintDevice(device))
					setOpenModal(false)
				} else {
					window.alert('It looks like you`ve chosen the wrong device. Make sure to choose the printer.');
				}

			}
		} catch (e) {
			window.alert('Error requesting printer device. Please try again.');
		}
	}

	return (
		<>
			{openModal &&
				<div className={`modal fade ${openModal ? ' show d-block' : ' d-none'}`} id="exampleModal1" aria-modal="true">
					<div className="modal-dialog modal-dialog-centered" >
						<div className="modal-content">
							<div className="modal-header">
								<h1 className="modal-title fs-5"> {t('checkout.printer_access')}</h1>
							</div>
							<div className="modal-body">
								<p>{t('checkout.printer_connect_string')}</p>
							</div>
							<div className="modal-footer">
								<Button
									name={t('common.cancel')}
									type="button"
									onClick={() => setOpenModal(!openModal)}
									className="link text-danger"
								/>
								<Button
									type="button"
									name={t('checkout.allow_access')}
									onClick={() => requestPrinterDevice()}
								/>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}
export default PrinterAccess