import React, { useEffect, useState } from 'react'
import { clientService, userService } from 'services'
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { closeModal, doDateFormatWithTime, generateFormattedAddress, getSortIcon, handleFilters, routes, serialNumber, strings } from 'utils'
import { _Object } from 'utils/interfaces'
import { Breadcrumbs, Button, InputField, LinkButton, Pagination, RadioButton, SEOHeader, SearchField, SelectField } from 'views/components'
import { countries } from 'utils/countries'
import { currenciesArray } from 'utils/currencies';
import { paymentMethod } from 'utils/service';

const Clients = () => {
  const { t } = useTranslation()
  const [id, setId] = useState('')
  const [loading, setLoading] = useState<_Object>
    ({
      listing: false,
      edit: false,
      update: false
    });
  const [customers, setCustomers] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })

  const [initialData, setInitialData] = useState<_Object>({
    initialValues: {
      name: '',
      email: '',
      phone_number1: '',
      gst: '',
      status: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      payment_methods: '',
      province: '',
      enable_receipt_print: false,
      enable_tax: false,
      tax_cal_method: 'including',
      postcode: '',
      enable_discount: false,
      currency: 'INR',
      user: {
        first_name: '',
        last_name: '',
        mobile_number: '',
        email: '',
        password: '',
        username: ''
      }
    },
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    clientService.getClient({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      data.items && setCustomers(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    if (id) {
      setLoading({ edit: true })
      clientService.getClientDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      phone_number1: Yup.string()
        .label('Mobile number')
        .required()
        .min(10, 'Mobile number must be at least 10 characters'),
      email: Yup.string().required().label('Email').email('Please enter a valid email'),
      tax_cal_method: Yup.string().required().label('Tax method'),
      status: Yup.string().required().label('Status'),
      name: Yup.string().required('Name is a required field'),
      payment_methods: Yup.array()
        .min(1, 'At least one payment method is required')
        .of(Yup.string().required('Each payment method must be selected'))
        .required('Payment methods are required')
        .label('Payment Methods'),

      user: !id ? Yup.object({
        first_name: Yup.string().trim().required('First name is required'),
        last_name: Yup.string().trim().required('Last name is required'),
        mobile_number: Yup.string()
          .required('Mobile Number is required')
          .min(10, 'Mobile number must be at least 10 characters'),
        email: Yup.string()
          .email('Please enter a valid email')
          .required('User email is required'),
        password: Yup.string()
          .required('Password is required'),
        username: Yup.string()
          .required('Username is required'),
      }) : Yup.object().nullable(),
    }),

    onSubmit: (values) => {
      values.email = values.email === '' ? `${values.phone_number1}@piecodes.in` : values.email
      setLoading({ update: true });

      if (id) {
        delete values.user
        delete values.id
        delete values.store_id
        delete values.updated_at
        delete values.created_at
        userService.updateClient(id, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCustomerModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })

      } else {
        clientService.createClient(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCustomerModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      }
    }
  })

  return (
    <>
      <SEOHeader title="Clients" />
      <Breadcrumbs
        trails={[
          {
            label: t('clients'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        <button type="button" onClick={() => {
          formik.resetForm(); setId('')
          if (!id) {
            setInitialData({
              name: '',
              email: '',
              phone_number1: '',
              gst: '',
              address1: '',
              address2: '',
              city: '',
              status: 'active',
              country: 'IN',
              province: '',
              enable_receipt_print: false,
              enable_tax: false,
              tax_cal_method: 'including',
              postcode: '',
              enable_discount: false,
              currency: 'INR',
              payment_methods: '',
              user: {
                first_name: '',
                last_name: '',
                mobile_number: '',
                email: '',
                password: '',
                username: ''
              }
            })
          } else {
            setInitialData({
              name: '',
              email: '',
              phone_number1: '',
              gst: '',
              payment_methods: '',
              address1: '',
              address2: '',
              city: '',
              status: 'active',
              country: 'IN',
              province: '',
              enable_receipt_print: false,
              enable_tax: false,
              tax_cal_method: 'including',
              postcode: '',
              enable_discount: false,
              currency: 'INR',
            })
          }
        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCustomerModal">
          <i className="fa-solid fa-plus text-white"></i>
          {t('Add Client')}
        </button>

        {/* Modal */}
        <div className="modal fade" id="addCustomerModal" tabIndex={-1} aria-labelledby="addCustomerModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form className="modal-content" onSubmit={formik.handleSubmit}>
              <div className="modal-content">
                <div className="modal-header p-4">
                  <h4 className="mb-0">{id ? 'Update client' : 'New client'}</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className={`modal-body p-4 ${loading.edit ? 'is-loading' : ''}`}>
                  <div className="row">
                    <InputField
                      name="name"
                      className="col-6"
                      label="Name"
                      required={true}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik?.touched.name && formik.errors.name && formik.errors.name}
                    />

                    <InputField
                      name="email"
                      className="col-6"
                      label="Email"
                      required={true}
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik?.touched.email && formik.errors.email && formik.errors.email}
                    />


                    <InputField
                      required={true}
                      className="col-6"
                      name="phone_number1"
                      label="Mobile number"
                      type="number"
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                        const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                        formik.setFieldValue('phone_number1', trimmedValue);
                      }}
                      value={formik.values.phone_number1}
                      error={formik?.touched.phone_number1 && formik.errors.phone_number1 && formik.errors.phone_number1}
                    />
                    <SelectField
                      isClearable
                      label={t('common.status')}
                      required={true}
                      className="col-6"
                      name="status"
                      options={[
                        {
                          label: 'Pending',
                          value: 'pending'
                        },
                        {
                          label: 'Active',
                          value: 'active'
                        },
                        {
                          label: 'Suspended',
                          value: 'suspended'
                        }
                      ]}
                      value={{ value: formik?.values?.status }}
                      onChange={(e: _Object) => {
                        formik.setFieldValue('status', e?.value || null)
                      }}
                      getOptionLabel={(option: { label: string }) => option?.label}
                      getOptionValue={(option: { value: string }) => option?.value}
                      error={formik.touched.status && formik.errors.status}
                    />

                    {!id &&
                      <div className="col-12">
                        <fieldset className="border py-1 px-3 mt-1">
                          <legend className="float-none w-auto px-1 mb-1"><h4 className="mb-0">Account</h4></legend>
                          <div className="row">
                            <InputField
                              name={'user.first_name'}
                              className="col-6"
                              label="First name"
                              onChange={formik.handleChange}
                              value={formik?.values?.user?.first_name}
                            />

                            <InputField
                              name={'user.last_name'}
                              className="col-6"
                              label="Last name"
                              onChange={formik.handleChange}
                              value={formik?.values?.user?.last_name}
                            />

                            <InputField
                              name={'user.email'}
                              value={formik?.values?.user?.email}
                              type="email"
                              label="Email"
                              className="col-6"
                              onChange={formik.handleChange}
                            />

                            <InputField
                              name={'user.mobile_number'}
                              className="col-6"
                              label="Mobile number"
                              onChange={formik.handleChange}
                              value={formik?.values?.user?.mobile_number}
                            />

                            <InputField
                              type="text"
                              name={'user.username'}
                              label="Username"
                              className="col-6"
                              onChange={formik.handleChange}
                              value={formik?.values?.user?.username}
                            />

                            <InputField
                              name={'user.password'}
                              className="col-6"
                              label="Password"
                              onChange={formik.handleChange}
                              value={formik?.values?.user?.password}
                            />

                          </div>
                        </fieldset>
                      </div>
                    }

                    <div className="col-12">
                      <fieldset className="border py-1 px-3 mt-1">
                        <legend className="float-none w-auto px-1 mb-1"><h4 className="mb-0">Address</h4></legend>
                        <div className="row">

                          <InputField
                            name="address1"
                            className="col-4"
                            label="Address 1"
                            onChange={formik.handleChange}
                            value={formik.values.address1}
                          />

                          <InputField
                            name="address2"
                            className="col-4"
                            label="Address 2"
                            onChange={formik.handleChange}
                            value={formik.values.address2}
                          />

                          <InputField
                            name="city"
                            className="col-4"
                            label="Town/city"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                          />

                          <InputField
                            type="text"
                            name="postcode"
                            label="Postcode"
                            className="col-4"
                            onChange={formik.handleChange}
                            value={formik.values.postcode}
                          />

                          <SelectField
                            className="col-4"
                            name="country"
                            label="Country"
                            options={countries}
                            value={{ value: formik.values.country || 'IN' }}
                            onChange={(e: _Object) => {
                              formik.setFieldValue('country', e?.value || '')
                            }}
                          />

                          <SelectField
                            className="col-4"
                            name="province"
                            label="Province"
                            options={countries?.find((obj: _Object) => obj.value === (formik?.values?.country?.length > 0 ? formik?.values?.country : 'IN'))?.states}
                            onChange={(e: _Object) => {
                              formik.setFieldValue('province', e?.label || '')
                            }}
                            value={{ label: formik.values.province }}
                            getOptionLabel={(option: { [key: string]: string }) => option?.label}
                            getOptionValue={(option: { [key: string]: string }) => option?.label}
                          />

                        </div>
                      </fieldset>
                    </div>

                    <div className="row mt-4">
                      <RadioButton
                        required={true}
                        name="enable_tax"
                        className="col-5"
                        label={'Enable tax'}
                        value={formik.values.enable_tax}
                        options={[
                          { label: t('common.yes'), value: true },
                          { label: t('common.no'), value: false },
                        ]}
                        onChange={(e: any) => {
                          const value = e.target.value === 'true';
                          formik.setFieldValue('enable_tax', value);
                        }}
                        error={formik?.touched.enable_tax && formik.errors.enable_tax}
                      />

                      <RadioButton
                        required={true}
                        name="tax_cal_method"
                        className="col-6"
                        label={'How you enter product price?'}
                        value={formik.values.tax_cal_method}
                        options={[
                          { label: 'Price with Tax', value: 'including' },
                          { label: 'Price without Tax', value: 'excluding' },
                        ]}
                        onChange={formik.handleChange}
                        error={formik?.touched.tax_cal_method && formik.errors.tax_cal_method}
                      />

                      <RadioButton
                        required={true}
                        name="enable_receipt_print"
                        className="col-5"
                        label={'Print receipt'}
                        value={formik.values.enable_receipt_print}
                        options={[
                          { label: t('common.yes'), value: true },
                          { label: t('common.no'), value: false },
                        ]}
                        onChange={(e: any) => {
                          const value = e.target.value === 'true';
                          formik.setFieldValue('enable_receipt_print', value);
                        }}
                        error={formik?.touched.enable_receipt_print && formik.errors.enable_receipt_print}
                      />

                      <RadioButton
                        required={true}
                        name="enable_discount"
                        className="col-5"
                        label={'Enable discount'}
                        value={formik.values.enable_discount}
                        options={[
                          { label: t('common.yes'), value: true },
                          { label: t('common.no'), value: false },
                        ]}
                        onChange={(e: any) => {
                          const value = e.target.value === 'true';
                          formik.setFieldValue('enable_discount', value);
                        }}
                        error={formik?.touched.enable_discount && formik.errors.enable_discount}
                      />

                      <SelectField
                        className="col-6"
                        name="currency"
                        label={t('common.currency')}
                        required={true}
                        options={currenciesArray}
                        onBlur={formik.handleBlur}
                        getOptionLabel={(option: { label: string, value: string }) => `${option?.label} - ${option?.value}`}
                        value={{ label: formik.values.currency || 'INR' }}
                        onChange={(e: _Object) => {
                          formik.setFieldValue('currency', e?.label || '')
                        }}
                        error={formik.values.status === '' && formik.touched.status && formik.errors.status}
                      />

                      <SelectField
                        name="payment_methods"
                        className="col-6"
                        label="Payment Methods"
                        required={true}
                        onBlur={formik.handleBlur}
                        options={paymentMethod}
                        value={paymentMethod?.filter((obj: { [key: string]: string, }) => formik.values.payment_methods?.includes(obj.value))}
                        onChange={(val: _Object) => {
                          formik.setFieldValue('payment_methods', val.map((data: { [key: string]: string }) => { return (data.value) }))
                        }}
                        error={formik.values.payment_methods === '' && formik.touched.payment_methods && formik.errors.payment_methods}
                        isMulti
                      />


                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <LinkButton path={`${routes.customers}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger fw-medium" />
                  <Button className="primary" name={id ? 'Update' : 'Submit'} loading={loading.update} />
                </div>
              </div>
            </form>
          </div>
        </div >
      </div >

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th>#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-full_name' ? 'full_name' : '-full_name', setFilterData) }}>
                    Name
                    {getSortIcon(filterData.sort.includes('full_name') ? filterData.sort : '')}
                  </button>
                </th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Address</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">Action</th>
              </tr>
            </thead>

            <tbody>
              {customers?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, customers?.pagination?.current_page, i)}</td>
                    <td>{item.name || '-'}</td>
                    <td className="break-spaces">{item.email}</td>
                    <td>{item.phone_number1}</td>
                    <td className="break-spaces">{generateFormattedAddress(item)}</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>

                    <td className="action">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id)} id="addCustomerModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addCustomerModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )
              })}

              {loading.listing === false && customers?.items?.length === 0 && <tr>
                <td colSpan={8} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>

      {
        customers?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={customers?.pagination?.current_page}
          per_page={customers?.pagination?.per_page}
          total_pages={customers?.pagination?.total_pages}
          total_items={customers?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}

export default Clients