import React, { useEffect, useState } from 'react';

import moment from 'moment';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official'

import { reportService } from 'services';
import { doDateFormat, doAmountFormat, handleSeries } from 'utils';
import { _Object } from 'utils/interfaces';
import ReportSkeleton from './skelton';

const Orders = (props: _Object) => {
  const { dateRange, isComparison } = props

  const [orders, setOrders] = useState<_Object>([])
  const [compareOrders, setCompareOrders] = useState<_Object>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    reportService.getOrders(dateRange.date1).then((data: _Object) => {
      setLoading(false);
      if (data?.items?.length > 0) {
        setOrders(data);
      } else {
        setOrders([]);
      }
    });
    if (isComparison) {
      reportService.getOrders(dateRange.date2).then((data: _Object) => {
        if (data?.items?.length > 0) {
          setCompareOrders(data);
        } else {
          setCompareOrders([]);
        }
      });
    }
  }, [dateRange.date1.start_date, dateRange.date2.start_date, isComparison]);

  const series1 = orders?.items?.length > 0 ? orders.items.map((item: _Object) => [
    moment(item.date).valueOf(),
    item.total,
  ]) : []

  const series2 = compareOrders?.items?.length > 0 ? compareOrders.items.map((item: _Object) => [
    moment(item.date).valueOf(),
    item.total,
  ]) : []

  const is12HourFormat = true;

  const options = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Total sales',
      align: 'left',
      margin: 30
    },
    subtitle: {
      text: `${doAmountFormat(orders?.total || 0)} <small style="font-size: 15px; color: rgb(51, 51, 51); font-weight: normal;">(Avg. order ${doAmountFormat(orders?.average || 0)})</small>`,
      align: 'left',
    },

    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,
      showFirstLabel: true,
      showLastLabel: false,
      labels: {
        style: {
          marginLeft: 5,
          align: 'left', // Align labels to the left
        },
      },

      dateTimeLabelFormats: {
        hour: is12HourFormat ? '%I:%M%P' : '%H:%M',
        day: '%b %e',
        week: '%b %e',
        month: '%b \'%y',
        year: '%Y',
      },

    },

    yAxis: {
      title: {
        text: null,
      },
      gridLineWidth: 1,
      lineWidth: 1,
    },

    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const date1: any = this.points?.[0]?.x;
        const total1 = (this.points?.[0]?.y ?? 0).toFixed(2);
        const orderNumber1 =
          compareOrders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date1))?.order_number ||
          orders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date1))?.order_number;

        let tooltipContent = 'Date: ' + doDateFormat(date1) +
          '<br />Order #: ' + orderNumber1 + '<br /><b>Total: ' + doAmountFormat(total1) + '</b>';

        // Include series2 data in the tooltip if available
        if (this.points?.[1]) {
          const date2: any = this.points[1]?.x;
          const total2 = (this.points[1]?.y ?? 0).toFixed(2);
          const orderNumber2 =
            compareOrders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date2))?.order_number ||
            orders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date2))?.order_number

          tooltipContent += '<br /><br />' +
            'Date: ' + doDateFormat(date2) +
            '<br />Order #: ' + orderNumber2 + '<br /><b>Total: ' + doAmountFormat(total2) + '</b>';
        }

        return tooltipContent;
      },
      style: {
        fontSize: '10px',
      },
    },
    series: handleSeries('chart', dateRange, series1, series2, isComparison),
  };

  return (
    <>
      {loading ? <ReportSkeleton />
        :
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={options} />
      }
    </>
  );
};

export default Orders;