import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { _Object } from 'utils/interfaces';
import { Breadcrumbs, Button, Expenses, Orders, SEOHeader, TopBuyers, TopSellingProducts } from 'views/components';
import './reports.scss'
import { doDateFormat, getSeriesGraphLabel } from 'utils';

import Highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';

const Reports = () => {

  accessibility(Highcharts);
  Highcharts.setOptions({
    accessibility: {
      enabled: true, // Enable accessibility features
    },
  });

  const [dateRange, setDateRange] = useState<_Object>({
    date1: {
      start_date: moment().startOf('year').format('YYYY-MM-DD'),
      end_date: moment().endOf('year').format('YYYY-MM-DD'),
    },
    date2: {
      start_date: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
      end_date: moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
    },
  });

  const [calendarDateRange, setCalendarDateRange] = useState<_Object>({
    startDate: new Date(moment().startOf('year').format('YYYY-MM-DD').toString()),
    endDate: new Date(moment().endOf('year').format('YYYY-MM-DD').toString()),
    key: 'selection',
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const [isComparison, setIsComparison] = useState(false);

  const staticRanges = [
    ...defaultStaticRanges,
    {
      label: 'Quarter to date',
      range: () => {
        return {
          startDate: new Date(moment().startOf('quarter').format('YYYY-MM-DD').toString()),
          endDate: new Date(moment().format('YYYY-MM-DD')),
        }
      },
      isSelected(range: _Object) {
        const definedRange = this.range();
        return moment(range.startDate).isSame(definedRange.startDate) && moment(range.endDate).isSame(definedRange.endDate)
      }
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: new Date(moment().startOf('year').format('YYYY-MM-DD').toString()),
        endDate: new Date(moment().endOf('year').format('YYYY-MM-DD').toString()),
      }),
      isSelected(range: _Object) {
        const definedRange = this.range();
        return moment(range.startDate).isSame(definedRange.startDate) && moment(range.endDate).isSame(definedRange.endDate)
      }
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: new Date(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD').toString()),
        endDate: new Date(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD').toString()),
      }),
      isSelected(range: _Object) {
        const definedRange = this.range();
        return moment(range.startDate).isSame(definedRange.startDate) && moment(range.endDate).isSame(definedRange.endDate)
      }
    },
  ]

  const handleCalendarClick = () => {
    setDateRange((prev) => ({
      ...prev,
      date1: {
        start_date: moment(calendarDateRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(calendarDateRange.endDate).format('YYYY-MM-DD'),
      },
    }));
    setShowCalendar(!showCalendar)

  };

  const handleCalendarLabel = () => {
    const dateRangeLabel = staticRanges.find((dateRage: _Object) => doDateFormat(dateRage.range().startDate) == doDateFormat(dateRange.date1.start_date) && doDateFormat(dateRage.range().endDate) == doDateFormat(dateRange.date1.end_date))
    return dateRangeLabel?.label ? dateRangeLabel?.label : getSeriesGraphLabel(dateRange.date1.start_date, dateRange.date1.end_date)
  };

  useEffect(() => {
    const startDate = moment(dateRange.date1.start_date);
    const endDate = moment(dateRange.date1.end_date);
    const diffDate = endDate.diff(startDate, 'days');

    let label;

    switch (true) {
      case diffDate == 0:
        label = 'today';
        break;
      case diffDate == 6:
        label = 'week';
        break;
      case diffDate == 29 || diffDate == 28 || diffDate == 30 || diffDate == 27:
        label = 'month';
        break;
      case diffDate == 364 || diffDate == 365:
        label = 'year';
        break;
    }

    let previousStartDate: _Object, previousEndDate: _Object;

    switch (label) {
      case 'today':
        previousStartDate = startDate.clone().subtract(1, 'day');
        previousEndDate = endDate.clone().subtract(1, 'day');
        break;
      case 'week':
        previousStartDate = startDate.clone().subtract(7, 'days');
        previousEndDate = endDate.clone().subtract(7, 'days');
        break;
      case 'month':
        previousStartDate = startDate.clone().subtract(1, 'month');
        previousEndDate = endDate.clone().subtract(1, 'month');
        break;
      case 'year':
        previousStartDate = startDate.clone().subtract(1, 'year');
        previousEndDate = endDate.clone().subtract(1, 'year');
        break;
      default:
        previousStartDate = startDate.clone().subtract(1, 'year');
        previousEndDate = endDate.clone().subtract(1, 'year');
        break;
    }

    setDateRange((prev) => ({
      ...prev,
      date2: {
        start_date: moment(previousStartDate).format('YYYY-MM-DD'),
        end_date: moment(previousEndDate).format('YYYY-MM-DD'),
      },
    }));
  }, [dateRange.date1.start_date, dateRange.date1.end_date]);

  return (
    <>
      <SEOHeader title="Analytics" />
      <Breadcrumbs
        trails={[
          {
            label: 'Analytics',
            path: ''
          }
        ]}
      />

      <ul className="list-inline align-items-center d-flex mb-2 date-button">
        <li className="list-inline-item">
          <button type="button" onClick={() => setShowCalendar(!showCalendar)} className="btn">
            <i className="fa fa-calendar-days me-1"></i>&nbsp;{handleCalendarLabel()}
          </button>
        </li>
        <li className="list-inline-item">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckCompare" checked={isComparison} onChange={() => {setIsComparison(!isComparison), handleCalendarLabel()}} />
            <label className="form-check-label" htmlFor="flexCheckCompare">
              Compare: Previous period
            </label>
          </div>
        </li>
      </ul>

      {showCalendar && (
        <div className="date-form">
          <div className="card calendra-wrapper">
            <DateRangePicker
              onChange={(item) => setCalendarDateRange(item.selection)}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[calendarDateRange]}
              direction="horizontal"
              maxDate={new Date()}
              monthDisplayFormat="MMMM yyyy"
              weekdayDisplayFormat="iiiiii"
              staticRanges={staticRanges}
            />

            <div className="border-top py-3 px-4">
              <ul className="list-inline text-end mb-0">
                <li className="list-inline-item" onClick={() => setShowCalendar(!showCalendar)}>
                  <Button type="button" className="btn text-capitalize" name="Cancel" onClick={() => setShowCalendar(!showCalendar)} />

                </li>
                <li className="list-inline-item">
                  <Button
                    type="button"
                    className="btn btn-primary text-capitalize"
                    name="Apply"
                    onClick={() => handleCalendarClick()}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 graph-chart">
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <Orders dateRange={dateRange} isComparison={isComparison} />
            </div>
          </div>
        </div>

        {/* <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <AverageOrders dateRange={dateRange} isComparison={isComparison} />
            </div>
          </div>
        </div> */}

        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <Expenses dateRange={dateRange} isComparison={isComparison} />
            </div>
          </div>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-md-2 g-4 my-3 graph-chart">
        <div className="col m-0">
          <div className="card h-100">
            <div className="card-body">
              <TopBuyers dateRange={dateRange}  />
            </div>
          </div>
        </div>

        <div className="col m-0">
          <div className="card h-100">
            <div className="card-body">
              <TopSellingProducts dateRange={dateRange}  />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
