import React, { useEffect, useState } from 'react'
import { userService } from 'services'
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { closeModal, doDateFormatWithTime, generateFormattedAddress, getSortIcon, handleFilters, routes, serialNumber, strings } from 'utils'
import { _Object } from 'utils/interfaces'
import { Breadcrumbs, Button, InputField, LinkButton, Pagination, SEOHeader, SearchField, SelectField } from 'views/components'
import { countries } from 'utils/countries'

const Customers = () => {
  const { t } = useTranslation()
  const [id, setId] = useState('')
  const [loading, setLoading] = useState<_Object>
    ({
      listing: false,
      edit: false,
      update: false
    });
  const [customers, setCustomers] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })

  const [initialData, setInitialData] = useState<_Object>({
    email: '',
    last_name: '',
    first_name: '',
    status: 'active',
    mobile_number: '',
    city: '',
    province: '',
    address1: '',
    address2: '',
    postcode: '',
    company_name: '',
    country: 'IN',
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    userService.getCustomers({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      data.items && setCustomers(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    if (id) {
      setLoading({ edit: true })
      userService.getCustomersDetails(id).then((data: _Object) => {
        setInitialData(data)
        console.log('data', data);

        console.log('initialData', initialData);

        setLoading({ edit: false })
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      mobile_number: Yup.string()
        .label('Mobile number')
        .required()
        .min(10, 'Mobile number must be at least 10 characters'),
      email: Yup.string().email('Please enter valid email'),
    }),

    onSubmit: (values) => {
      values.email = values.email === '' ? `${values.mobile_number}@piecodes.in` : values.email
      setLoading({ update: true });

      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at
        delete values.full_name
        delete values.client_id

        userService.updateCustomers(id, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCustomerModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })

      } else {
        values.postcode = `${values.postcode}`

        userService.createCustomers(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCustomerModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      }
    }
  })

  return (
    <>
      <SEOHeader title="Customers" />
      <Breadcrumbs
        trails={[
          {
            label: t('customers'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        <button type="button" onClick={() => {
          formik.resetForm(); setId('')
            ; setInitialData({
              email: '',
              last_name: '',
              first_name: '',
              status: 'active',
              mobile_number: '',
              city: '',
              province: '',
              address1: '',
              address2: '',
              postcode: '',
              company_name: '',
              country: 'IN',
            })
        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCustomerModal">
          <i className="fa-solid fa-plus text-white"></i>
          {t('customer.add_customer')}
        </button>

        {/* Modal */}
        <div className="modal fade" id="addCustomerModal" tabIndex={-1} aria-labelledby="addCustomerModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form className="modal-content" onSubmit={formik.handleSubmit}>
              <div className="modal-content">
                <div className="modal-header p-4">
                  <h4 className="mb-0">{id ? 'Update customer' : 'New customer'}</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className={`modal-body p-4 ${loading.edit ? 'is-loading' : ''}`}>
                  <div className="row">
                    <InputField
                      name="first_name"
                      className="col-6"
                      label="First name"
                      // required={true}
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                    // error={formik?.touched.first_name && formik.errors.first_name && formik.errors.first_name}
                    />

                    <InputField
                      name="last_name"
                      className="col-6"
                      label="Last name"
                      // required={true}
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                    // error={formik?.touched.last_name && formik.errors.last_name && formik.errors.last_name}
                    />

                    <InputField
                      required={true}
                      className="col-4"
                      name="mobile_number"
                      label="Mobile number"
                      type="number"
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                        const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                        formik.setFieldValue('mobile_number', trimmedValue);
                      }}
                      value={formik.values.mobile_number}
                      error={formik?.touched.mobile_number && formik.errors.mobile_number && formik.errors.mobile_number}
                    />

                    <InputField
                      name="email"
                      className="col-4"
                      label="Email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik?.touched.email && formik.errors.email && formik.errors.email}
                    />

                    <InputField
                      label="Company"
                      className="col-4"
                      name="company_name"
                      onChange={formik.handleChange}
                      value={formik.values.company_name}
                    />


                    <div className="col-12">
                      <fieldset className="border py-1 px-3 mt-1">
                        <legend className="float-none w-auto px-1 mb-1"><h4 className="mb-0">Address</h4></legend>
                        <div className="row">

                          <InputField
                            name="address1"
                            className="col-4"
                            label="Address 1"
                            onChange={formik.handleChange}
                            value={formik.values.address1}
                          />

                          <InputField
                            name="address2"
                            className="col-4"
                            label="Address 2"
                            onChange={formik.handleChange}
                            value={formik.values.address2}
                          />

                          <InputField
                            name="city"
                            className="col-4"
                            label="Town/city"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                          />

                          <InputField
                            type="text"
                            name="postcode"
                            label="Postcode"
                            className="col-4"
                            onChange={formik.handleChange}
                            value={formik.values.postcode}
                          />

                          <SelectField
                            className="col-4"
                            name="country"
                            label="Country"
                            options={countries}
                            value={{ value: formik.values.country || 'IN' }}
                            onChange={(e: _Object) => {
                              formik.setFieldValue('country', e?.value || '')
                            }}
                          />

                          <SelectField
                            className="col-4"
                            name="province"
                            label="Province"
                            options={countries?.find((obj: _Object) => obj.value === (formik?.values?.country?.length > 0 ? formik?.values?.country : 'IN'))?.states}
                            onChange={(e: _Object) => {
                              formik.setFieldValue('province', e?.label || '')
                            }}
                            value={{ label: formik.values.province }}
                            // disabled={!formik.values.country}
                            getOptionLabel={(option: { [key: string]: string }) => option?.label}
                            getOptionValue={(option: { [key: string]: string }) => option?.label}
                          />

                          {/* <InputField
                            name="state"
                            label="State"
                            className="col-4"
                            onChange={formik.handleChange}
                            value={formik.values.state}
                          /> */}

                          {/* <SelectField
                            className="col-4"
                            name="country"
                            label="Country"
                            options={countryData()}
                            value={{ name: formik.values.country || 'India' }}
                            onChange={(data: _Object) => {
                              formik.setFieldValue('country', data.name)
                            }}
                            getOptionLabel={(option: _Object) => option.name}
                            getOptionValue={(option: _Object) => option.isoCode || option.name}
                          /> */}

                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <LinkButton path={`${routes.customers}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger fw-medium" />
                  <Button className="primary" name={id ? 'Update' : 'Submit'} loading={loading.update} />
                </div>
              </div>
            </form>
          </div>
        </div >
      </div >

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th>#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-full_name' ? 'full_name' : '-full_name', setFilterData) }}>
                    Name
                    {getSortIcon(filterData.sort.includes('full_name') ? filterData.sort : '')}
                  </button>
                </th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Address</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">Action</th>
              </tr>
            </thead>

            <tbody>
              {customers?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, customers?.pagination?.current_page, i)}</td>
                    <td>{item.full_name || '-'}</td>
                    <td className="break-spaces">{item.email}</td>
                    <td>{item.mobile_number}</td>
                    <td className="break-spaces">{generateFormattedAddress(item)}</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>

                    <td className="action">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id)} id="addCustomerModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addCustomerModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )
              })}

              {loading.listing === false && customers?.items?.length === 0 && <tr>
                <td colSpan={8} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>

      {
        customers?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={customers?.pagination?.current_page}
          per_page={customers?.pagination?.per_page}
          total_pages={customers?.pagination?.total_pages}
          total_items={customers?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}

export default Customers