import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class ClientService extends CommonService {

  async getClient(params: _Object | string) {
    return await this.get(`clients?${generateQueryParams(params)}`)
  }
  async getClientDetails(id: _Object | string) {
    return await this.get(`clients/${id}`)
  }
  async createClient(params: { [key: string]: boolean | string | null }) {
    return await this.post('clients', params)
  }
  async updateClient(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`clients/${id}`, params)
  }
  async deleteClient(id: string) {
    return await this.delete(`clients/${id}`)
  }
}
export const clientService = new ClientService();
