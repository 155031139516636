import CommonService from './common.service';
import { _Object } from 'utils/interfaces';
import { generateQueryParams } from 'utils';

class UserService extends CommonService {
  async getUsers(params: _Object | '') {
    return await this.get(`users?${generateQueryParams(params)}`)
  }
  async getUserDetails(id: string) {
    return await this.get(`users/${id}`)
  }
  async createUser(params: { [key: string]: boolean | string | null }) {
    return await this.post('users', params)
  }
  async updateUser(id: string, params: { [key: string]: boolean | string | null }) {
    return await this.put(`users/${id}`, params)
  }
  async deleteUser(id: string) {
    return await this.delete(`users/${id}`)
  }
  async getUsersReport() {
    return await this.get('report/users')
  }

  async getClient(id: string) {
    return await this.get(`clients/${id}`)
  }

  async updateClient(id: string, params: { [key: string]: boolean | string | null }) {
    return await this.put(`clients/${id}`, params)
  }

  /*Customers*/
  async getCustomers(params: _Object | string) {
    return await this.get(`customers?${generateQueryParams(params)}`)
  }
  async getCustomersDetails(id: string) {
    return await this.get(`customers/${id}`)
  }
  async createCustomers(params: { [key: string]: boolean | string | null }) {
    return await this.post('customers', params)
  }
  async updateCustomers(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`customers/${id}`, params)
  }
  async deleteCustomers(id: string) {
    return await this.delete(`customers/${id}`)
  }

  /* Customer Search */
  async getCustomerSearch(params: _Object | string) {
    return await this.get(`customers/search?${generateQueryParams(params)}`)
  }
}

export const userService = new UserService();
