import React, { useEffect, useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { productService } from 'services';
import { routes, serialNumber, doDateFormatWithTime, strings, closeModal, handleFilters, getSortIcon } from 'utils';
import { _Object } from 'utils/interfaces';
import { InputField, LinkButton, Pagination, SearchField, Button, TabsHeader, Breadcrumbs } from 'views/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ProductAttributes = () => {
	const { t } = useTranslation()

  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)
  const [productAttributes, setProductAttributes] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    q: '',
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })
  const [initialData, setInitialData] = useState<_Object>({
    name: '',
  })

  useEffect(() => {
    setLoading(true)
    productService.getProductAttributes(filterData).then((data: _Object) => {
      setProductAttributes(data)
      setLoading(false)
    })
  }, [filterData])

  useEffect(() => {
    if (id) {
      setLoading(true)
      productService.getProductAttributesDetails(id).then((data) => {
        setInitialData(data)
        setLoading(false)
      })
    }
  }, [id])

  const deleteItem = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading(true)
      productService.deleteProductAttribute(id).then(() => {
        productService.getProductAttributes(filterData).then((data: _Object) => {
          setProductAttributes(data)
          setLoading(false)
        })
      })
    }
  }

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      name: Yup.string().label('Name').required(),
    }),

    onSubmit: (values) => {
      setLoading(true)

      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at

        productService.updateProductAttribute(id, values).then((data: _Object) => {
          setTimeout(() => {
            if (data.error === false) {
              setLoading(true)
              formik.resetForm();
              productService.getProductAttributes(filterData).then((data: _Object) => {
                setProductAttributes(data)
                setLoading(false)
              })
              closeModal('productAttributesModal');
            } else {
              throw new Error(data.error);
            }
          }, 1000);
        }).catch((error) => {
          error && setLoading((false))
        })

      } else {
        // values.slug ? values.slug : delete values.slug
        productService.createProductAttribute(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading(true)
            formik.resetForm();
            productService.getProductAttributes(filterData).then((data: _Object) => {
              setProductAttributes(data)
              setLoading(false)
            })
            closeModal('productAttributesModal');
          } else {
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading((false))
        })
      }
    }

  })


  return (
    <>
      <Breadcrumbs
        trails={[
          {
            label: 'Attributes',
            path: ''
          }
        ]}
      />

      <TabsHeader />

      <div className="pos-justify pos-between pos-align pos-center">
        <form className="row filters" onSubmit={formik.handleSubmit}>
          <SearchField
            type="search"
            name="q"
            className="mb-md-0"
            value={filterData.q}
            onChange={(e: _Object) => {
              setFilterData((prev) => ({
                ...prev,
                page: 1,
                q: e.target.value,
              }))
            }}
          />
        </form>

        <button type="button"
          onClick={() => {
            formik.resetForm(); setId('');
            setInitialData({ name: '' })
          }}
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#productAttributesModal">
          <i className="fa-solid fa-plus text-white"></i>
          Add New
        </button>

        {/* Modal */}
        <div className="modal fade" id="productAttributesModal" aria-labelledby="addProductAttributeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="mb-0">{id ? 'Update attribute' : 'New attribute'}</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <InputField
                    name="name"
                    label="Title"
                    required={true}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && formik.errors.name}
                  />

                  {/* <div className="row pe-0">
                    <InputField
                      className="col"
                      name="option"
                      label="Option"
                      // required={true}
                      // value={formik.values.name}
                      // onChange={formik.handleChange}
                      // error={formik.touched.name && formik.errors.name}
                    />

                    <div className="col-auto align-self-center pe-0">

                      <button type="button" className="btn btn-primary">+</button>
                    </div>
                  </div>

                  <div className="row pe-0">
                    <InputField
                      className="col"
                      name="option"
                      label="Option"
                      // required={true}
                      // value={formik.values.name}
                      // onChange={formik.handleChange}
                      // error={formik.touched.name && formik.errors.name}
                    />

                    <div className="col-auto align-self-center pe-0">
                      <button type="button" className="btn btn-primary">+</button>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.attributes}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger" />
                <Button type="submit" className="primary" name={id ? 'Update' : 'Submit'} loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <table className="card-body mb-0 table table-borderless table-striped order-listing">
          <thead>
            <tr className="align-middle bg-white">
              <th>#</th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                  Title
                  {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                </button>
              </th>
              <th>Options</th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                  {t('common.createDate')}
                  {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                </button>
              </th>

              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                  Update date
                  {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                </button>
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody className={loading === true ? 'is-loading' : ''}>
            {productAttributes?.items?.map((data: _Object, i: number) => {
              return (
                <tr className="align-middle" key={i}>
                  <td>{serialNumber(filterData.per_page, filterData.page, i)}</td>
                  <td>
                    {data.name}
                  </td>
                  <td>
                    <LinkButton path={`${routes.attributes}/${data.id}/options`} label="" className="link text-dark p-0">
                      <FontAwesomeIcon icon={faGear} className="m-0" />
                    </LinkButton>
                    {/* <span className="d-flex align-items-center">
                      <LinkButton path={`${routes.attributes}/${data.id}/values`} label="Option Config" className="link p-0 text-dark fw-normal" />
                    </span> */}
                  </td>
                  <td>{doDateFormatWithTime(data.created_at)}</td>
                  <td>{doDateFormatWithTime(data.updated_at)}</td>
                  <td>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <button onClick={() => setId(data.id)} id="productAttributesModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#productAttributesModal">
                          <i className="fa fa-pen"></i>
                        </button>
                      </li>
                      <li className="list-inline-item">
                        <Button type="submit" className="link text-dark p-0" onClick={() => deleteItem(data.id)}>
                          <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                        </Button>
                      </li>
                    </ul>
                  </td>
                </tr>
              )
            })}

            {loading === false && productAttributes?.items?.length === 0 && <tr>
              <td colSpan={12} className="text-center no-entry">
                <aside>
                  <i className="fa-solid fa-ban"></i>
                  {strings.noRecordFound}
                </aside>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      {productAttributes?.pagination?.total_pages > 1 &&
        <Pagination
          className="pagination-bar"
          current_page={productAttributes?.pagination?.current_page}
          per_page={productAttributes?.pagination?.per_page}
          total_pages={productAttributes?.pagination?.total_pages}
          total_items={productAttributes?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default ProductAttributes


{/* Offcanvas Drawer*/}
{/* <div className="offcanvas-body pt-0 mt-4">
  <table className="table table-bordereless table-striped">
    <thead>
      <tr>
        <th className="text-start">Label</th>
        <th className="text-end">View</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="text-start">M</td>
        <td className="text-end">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button id="productAttributeValueModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#productAttributeValueModal">
                <i className="fa fa-pen"></i>
              </button>
            </li>

            <li className="list-inline-item">
              <Button type="submit" className="link text-dark p-0">
                <i className="fa fa-trash text-danger"></i>
              </Button>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</div> */}

{/* Offcanvas footer */}
{/* <div className="offcanvas-footer d-grid gap-2 p-3">
  <button type="button"
    className="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#productAttributesModal">
    Create
  </button>
</div> */}


{/* Attribute Value Modal */}
{/* <div className="modal fade" id="productAttributeValueModal" aria-labelledby="productAttributeValueModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <form className="modal-content" onSubmit={formik.handleSubmit}>
      <div className="modal-header">
        <h4 className="modal-title" id="productAttributeValueModalLabel">
          Create attribute value
        </h4>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div className={`modal-body ${loading === true ? 'is-loading' : ''}`}>
        <InputField
          name="name"
          label="Title"
          required={true}
        // value={formik.values?.data[0]?.name}
        // onChange={(e: any) => formik.setFieldValue('data[0].name', e.target.value)}
        // error={formik?.touched?.data && formik?.touched?.data[0]?.name && formik?.errors?.data && formik?.errors?.data[0]?.name}
        />
      </div>

      <div className="modal-footer">
        <LinkButton path={`${routes.attributesValues}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger fw-medium" />
        <Button className="primary"
          // name={modalId ? 'Update' : 'Submit'}
          name="Submit"
          loading={loading} />
      </div>
    </form>
  </div>
</div>

<div className="offcanvas offcanvas-end" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div className="offcanvas-header border-bottom">
    <h5 className="offcanvas-title" id="offcanvasRightLabel">Size - Options</h5>
    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
</div> */}