import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'
import { useFormik, } from 'formik';

import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { AsyncSelectField, Button, LinkButton, SelectField } from 'views/components/form-inputs';
import { productService, categoryService, brandService, supplierService } from 'services'

import { Breadcrumbs, SEOHeader, InputField, RadioButton, TextArea } from 'views/components'

const CreateProduct = () => {
  const { t } = useTranslation()

  const selectAsyncSelect: any = useRef([])
  const [isEditMode, setEditMode] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [categories, setCategories] = useState<_Object>([])
  const [brands, setBrands] = useState<_Object>([])
  const [suppliers, setSuppliers] = useState<_Object>([])
  const [taxRates, setTaxRates] = useState<_Object>([])
  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    categoryService.getCategories('').then((data: _Object) => {
      setCategories(data.items)
    })
    // tagService.getTags('').then((data: _Object) => {
    //   setTags(data.items)
    // })
    brandService.getBrands('').then((data: _Object) => {
      setBrands(data.items)
    })
    productService.taxRate({ per_page: 9999 }).then((data: _Object) => {
      setTaxRates(data?.items)
    })
  }, [])

  useEffect(() => {
    setInitialData({
      sku: null,
      name: '',
      user_id: null,
      barcode: null,
      brand_id: null,
      category_id: null,
      supplier_id: null,
      description: '',
      regular_price: null,
      buying_price: null,
      manage_stock: false,
      discounted_price: null,
      short_description: '',
      stock_status: 'instock',
      low_stock_threshold: '',
      tax_status: false,
      status: 'published',
      tax_rate_id: null,
      width: null,
      length: null,
      height: null,
      weight: null,
    })

    setEditMode(false)
    if (id) {
      setLoading({ edit: true })
      setEditMode(true)
      productService.getProductDetails(id).then((data: _Object) => {
        setInitialData(
          {
            ...data,
            categories: [{ name: data.category?.name, id: data.category?.id }],
            suppliers: { label: data.supplier?.name, value: data.supplier?.id },
            stock_status: data?.stock_status?.length > 0 ? data.stock_status : data.manage_stock === 'true' ? 'outofstock' : 'instock'
          }
        )
        setLoading({ edit: false })
      })
    }
  }, [id])

  const formik: _Object = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Title').required(t('product.title_required')),
      // sku: Yup.string().label('SKU').required(t('product.sku_required')),
      // barcode: Yup.string().label('Barcode').required(t('product.barcode_required')),
      // brand_id: Yup.string().label('Brand').required(),
      category_id: Yup.string().label('Category').required(),
      tax_rate_id: initialData.tax_status === false ? Yup.string().nullable() : Yup.string().label('Tax rate').required(t('product.tax_rate_id_required')),

      regular_price: Yup.number()
        .label('Regular price')
        .min(0, t('product.regular_price_greater_then_zero'))
        .test('decimal-places', t('product.regular_price_two_decimal'), value => {
          if (!value) return true;
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        })
        .required(t('product.regular_price_required')),

      buying_price: Yup.number()
        .nullable()
        .min(0, t('product.buying_price_greater_then_zero'))
        .test('decimal-places', t('product.buying_price_two_decimal'), value => {
          if (!value) return true;
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        }),

      discounted_price: Yup.string()
        .nullable()
        .test('decimal-places', t('product.discounted_price_two_decimal'), value => {
          if (!value) return true;
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        })
        .when('regular_price', ([regular_price], schema) => {
          return schema.test('is-less-than-regular-price', t('product.discounted_price_lass_then_regular'), function (value: any) {
            const regularPrice = parseFloat(regular_price);
            const discountedPrice = parseFloat(value);

            if (!isNaN(regularPrice) && !isNaN(discountedPrice) && discountedPrice >= regularPrice) {
              return this.createError({
                path: 'discounted_price',
                message: t('product.discounted_price_lass_then_regular'),
              });
            }
            return true;
          });
        }),

      length: Yup.number()
        .nullable()
        .positive(t('product.length_required'))
        .notOneOf([0], t('product.length_zero_required')),

      width: Yup.number()
        .nullable()
        .positive(t('product.width_required'))
        .notOneOf([0], t('product.width_zero_required')),

      height: Yup.number()
        .nullable()
        .positive(t('product.height_required'))
        .notOneOf([0], t('product.height_zero_required')),

      weight: Yup.number()
        .nullable()
        .positive(t('product.weight_required'))
        .notOneOf([0], t('product.weight_zero_required')),

    }),

    onSubmit: (values) => {

      setLoading({ update: true });
      if (id) {
        const params = {
          'name': values.name,
          'sku': values.sku,
          'barcode': values.barcode,
          'description': values.description,
          'short_description': values.short_description,
          'buying_price': values.buying_price,
          'regular_price': values.regular_price,
          'discounted_price': values.discounted_price,
          'status': values.status,
          'manage_stock': values.manage_stock,
          'stock_status': values.stock_status,
          'low_stock_threshold': values.low_stock_threshold,
          'weight': values.weight,
          'length': values.length,
          'width': values.width,
          'height': values.height,
          'brand_id': values.brand_id,
          'category_id': values.category_id,
          'supplier_id': values.supplier_id,
          'tax_status': values.tax_status,
          'tax_rate_id': values.tax_rate_id
        }
        values.barcode = values?.barcode?.length > 0 ? values?.barcode : null
        productService.updateProduct(id, params).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data?.error == false) {
            setLoading({ update: false, listing: false })
            navigate(`${routes.products}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data?.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      } else {
        delete values.user_id
        delete values.data
        delete values?.categories
        delete values?.brand
        delete values?.suppliers
        values.low_stock_threshold = values.low_stock_threshold > 0 ? values.low_stock_threshold : 0
        values.barcode = values?.barcode?.length > 0 ? values?.barcode : null
        productService.createProduct(values).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data?.error === false) {
            navigate(`${routes.products}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data?.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      }
    },
  })

  useEffect(() => {
    supplierService.getSuppliers({}).then((data: _Object) => {
      setSuppliers(data.items)
    })
  }, [formik.values.brand_id])

  const categoriesOption = async (categoriesValue: string) => {
    const data: _Object = await categoryService.getCategories({ q: categoriesValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  }

  const brandOption = async (brandValue: string) => {
    const data: _Object = await brandService.getBrands({ q: brandValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  }

  const supplierOption = async (supplierValue: string) => {
    const data: _Object = await supplierService.getSuppliers({ q: supplierValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  }

  const genrateSku = async () => {
    const data: _Object = await productService.createProductSKU({ category_id: formik.values.category_ids[0], product_title: formik.values.name })

    if (data.error === false) {
      formik.setFieldValue('sku', data.data)
    }
  }

  const genrateBarcode = async () => {
    const data: _Object = await productService.createProductBarcode({ category_id: formik.values.category_ids[0], product_title: formik.values.name })

    if (data.error === false) {
      formik.setFieldValue('barcode', data.data)
    }
  }
  console.log('formik.values.manage_stock', typeof formik.values.manage_stock);

  return (
    <>
      <SEOHeader title="Add product" />

      <Breadcrumbs
        trails={[
          {
            label: t('product.products'),
            path: `${routes.products}`
          },
          {
            label: `${isEditMode ? t('product.update') : t('product.new')}`,
            path: ''
          }
        ]}
        pageTitle={`${isEditMode ? t('product.update') : t('product.new')}`}
      />
      <form className={`row filters ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-xs-3 mb-md-0">
          <div className="card">
            <div className="card-header p-3">
              <h4 className="mb-0">{t('product.general_information')}</h4>
            </div>

            <div className="card-body">
              <div className="row">
                <InputField
                  name="name"
                  label={t('common.title')}
                  required={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />

                <InputField
                  className="col-4"
                  type="number"
                  name="regular_price"
                  label={t('product.regular_price')}
                  required={true}
                  value={formik.values.regular_price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.regular_price && formik.errors.regular_price}
                />

                <InputField
                  className="col-4"
                  type="number"
                  name="discounted_price"
                  label={t('product.sale_price')}
                  value={formik.values.discounted_price}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('discounted_price', e?.target?.value || null)
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.discounted_price && formik.errors.discounted_price}
                />

                <InputField
                  className="col-4"
                  type="number"
                  name="buying_price"
                  label={t('product.buying_price')}
                  value={formik.values.buying_price}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('buying_price', e?.target?.value || null)
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.buying_price && formik.errors.buying_price}
                />

                <SelectField
                  isClearable
                  label={t('common.status')}
                  required={true}
                  className="col-4"
                  name="status"
                  options={[
                    {
                      label: 'Published',
                      value: 'published'
                    },
                    {
                      label: 'Draft',
                      value: 'draft'
                    },
                    {
                      label: 'Discontinued',
                      value: 'discontinued'
                    },
                  ]}
                  value={{ value: formik?.values?.status }}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('status', e?.value || null)
                  }}
                  getOptionLabel={(option: { label: string }) => option?.label}
                  getOptionValue={(option: { value: string }) => option?.value}
                  error={formik.touched.status && formik.errors.status}
                />

                <RadioButton
                  name="tax_status"
                  required={true}
                  className="col-4"
                  label={t('product.tax_status')}
                  value={formik?.values?.tax_status}
                  options={[
                    { label: t('common.yes'), value: true },
                    { label: t('common.no'), value: false },
                  ]}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('tax_status', e?.target?.value === 'true' ? true : false)
                    setInitialData(() => ({
                      ...formik.values,
                      tax_status: e?.target?.value === 'true' ? true : false,
                      tax_rate_id: e?.target?.value === 'false' ? null : null
                    }))
                  }}
                />

                {formik?.values?.tax_status === true &&
                  <SelectField
                    isClearable
                    label={t('product.tax_rate')}
                    required={true}
                    className="col-4"
                    name="tax_rate_id"
                    options={taxRates?.map((item: any) => ({
                      label: (
                        <div>
                          <span>{item?.title?.toUpperCase()}</span> - <small>{item.rate}%</small>
                        </div>
                      ),
                      value: item?.id
                    }))} value={{ value: formik?.values?.tax_rate_id }}
                    onChange={(e: _Object) => {
                      formik.setFieldValue('tax_rate_id', e?.value || null)
                    }}
                    getOptionLabel={(option: _Object) => option?.label}
                    getOptionValue={(option: _Object) => option?.value}
                    error={formik.touched.tax_rate_id && formik.errors.tax_rate_id}
                  />
                }

                <TextArea
                  name="description"
                  label={t('common.description')}
                  placeholder={t('common.enter_description')}
                  rows={5}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <div className="card">
            <div className="card-header p-3">
              <h4 className="mb-0">{t('product.manage_stocks')}</h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-6">

                  <InputField
                    name="sku"
                    // required={true}
                    label={t('common.sku')}
                    // linkLabel={t('product.genrate')}
                    linkDisable={formik.values?.name?.length > 0 && formik.values?.category_ids?.length > 0}
                    linkOnChange={genrateSku}
                    value={formik.values.sku}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sku && formik.errors.sku}
                  />
                </div>


                <div className="col-6">

                  <InputField
                    type="text"
                    name="barcode"
                    // required={true}
                    label={t('product.barcode')}
                    // linkLabel={t('product.genrate')} 
                    linkDisable={formik.values?.name?.length > 0 && formik.values?.category_ids?.length > 0}
                    linkOnChange={genrateBarcode}
                    value={formik.values.barcode}
                    onChange={formik.handleChange}
                    error={formik.touched.barcode && formik.errors.barcode}
                  />
                </div>

                <InputField
                  className="col-3"
                  label={t('product.dimensions')}
                  type="number"
                  name="weight"
                  placeholder={t('product.weight')}
                  value={formik.values.weight}
                  onBlur={formik.handleBlur}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('weight', e?.target?.value || null)
                  }}
                  error={formik.touched.weight && formik.errors.weight}
                />

                <InputField
                  className="col-3"
                  label="&nbsp;"
                  type="number"
                  name="length"
                  placeholder={t('product.length')}
                  value={formik.values.length}
                  onBlur={formik.handleBlur}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('length', e?.target?.value || null)
                  }}
                  error={formik.touched.length && formik.errors.length}
                />

                <InputField
                  className="col-3"
                  label="&nbsp;"
                  type="number"
                  name="width"
                  placeholder={t('product.width')}
                  value={formik.values.width}
                  onBlur={formik.handleBlur}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('width', e?.target?.value || null)
                  }}
                  error={formik.touched.width && formik.errors.width}
                />

                <InputField
                  className="col-3"
                  type="number"
                  label="&nbsp;"
                  name="height"
                  placeholder={t('product.height')}
                  value={formik.values.height}
                  onBlur={formik.handleBlur}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('height', e?.target?.value || null)
                  }}
                  error={formik.touched.height && formik.errors.height}
                />

                <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                  <AsyncSelectField
                    isClearable
                    cacheOptions
                    required={true}
                    label={t('category.categories')}
                    name="category_ids"
                    placeholder={t('common.choose_option')}
                    loadOptions={categoriesOption}
                    ref={(ref: any) => (selectAsyncSelect.current = ref)}
                    defaultOptions={categories?.map((item: _Object) => {
                      return {
                        label: item.name, value: item.id
                      }
                    })}
                    onChange={(val: _Object) => {
                      formik.setFieldValue('category_id', val?.value)
                      formik.setFieldValue('categories', [{ name: val?.label, id: val?.value }])
                    }}
                    value={
                      formik?.values?.categories && formik?.values?.categories[0]?.name
                        ? {
                          label: formik.values?.categories[0]?.name,
                          value: formik.values?.categories[0]?.id,
                        }
                        : null
                    }
                    onBlur={formik.handleBlur}
                    error={!formik.values.category_id && formik.errors.category_id}
                  />
                </div>

                <div className="col-6 col-md-6 col-lg-6 col-xl-3 manage-stock-status">
                  {/* <div className="manage-stock-status d-flex gap-3"> */}
                  <RadioButton
                    name="manage_stock"
                    // className="col-3"
                    label={t('product.manage_stock')}
                    value={formik.values.manage_stock}
                    options={[
                      { label: t('common.yes'), value: true },
                      { label: t('common.no'), value: false },
                    ]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('manage_stock', e.target.value === 'true' ? true : false)
                    }}
                  />
                </div>

                {formik.values.manage_stock === false &&
                  <div className="col-6 col-md-6 col-lg-6 col-xl-6 manage-stock-status">
                    <RadioButton
                      name="stock_status"
                      // className="col-4"
                      label={t('product.stock_status')}
                      onChange={formik.handleChange}
                      value={formik.values.stock_status}
                      options={[
                        { label: t('product.in_stock'), value: 'instock' },
                        { label: t('product.out_of_stock'), value: 'outofstock' },
                      ]}
                    />
                  </div>
                }

                {formik.values.manage_stock === true &&
                  <InputField
                    type="number"
                    name="low_stock_threshold"
                    className="col"
                    label={t('product.low_stock_threshold')}
                    value={formik.values.low_stock_threshold}
                    onChange={formik.handleChange}
                  />
                }

                <div className="col-6">
                  <AsyncSelectField
                    isClearable
                    cacheOptions
                    label={t('common.brands')}
                    name="brand_id"
                    // required={true}
                    loadOptions={brandOption}
                    placeholder={t('common.choose_option')}
                    ref={(ref: any) => (selectAsyncSelect.current = ref)}
                    defaultOptions={brands?.map((item: _Object) => ({
                      label: item.name,
                      value: item.id
                    }))}
                    onChange={(val: _Object) => {
                      formik.setFieldValue('brand_id', val?.value || null)
                      formik.setFieldValue('brand', { name: val?.label, id: val?.value })
                      if (isEditMode) {
                        formik.setFieldValue('supplier_ids', [])
                        formik.setFieldValue('suppliers', [])
                      }
                    }}
                    value={
                      formik?.values?.brand?.name
                        ? {
                          label: formik.values.brand?.name,
                          value: formik.values.brand?.id,
                        }
                        : null
                    }
                    onBlur={formik.handleBlur}
                  // error={formik.touched.brand_id && formik.errors.brand_id}
                  />
                </div>

                <div className="col-6">
                  <AsyncSelectField
                    isClearable
                    cacheOptions
                    label={t('supplier.suppliers')}
                    name="supplier_ids"
                    disabled={!formik.values.brand_id}
                    loadOptions={supplierOption}
                    placeholder={t('common.choose_option')}
                    ref={(ref: any) => (selectAsyncSelect.current = ref)}
                    defaultOptions={suppliers
                      .map((matchedItem: any) => ({
                        label: matchedItem?.name,
                        value: matchedItem?.id
                      })) || []}
                    // defaultOptions={suppliers
                    //   .filter((item: any) => item?.brands && item?.brands.some((brand: any) => brand?.id === formik.values.brand_id))
                    //   .map((matchedItem: any) => ({
                    //     label: matchedItem?.name,
                    //     value: matchedItem?.id
                    //   })) || []}

                    onChange={(val: _Object) => {
                      formik.setFieldValue('supplier_id', val.value || [])
                      formik.setFieldValue('suppliers', val || [])
                    }}
                    value={
                      formik?.values?.suppliers?.label
                        ? {
                          label: formik.values.suppliers?.label,
                          value: formik.values.suppliers?.value,
                        }
                        : null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex gap-3 my-3">
          <Button type="submit" name={isEditMode ? t('common.update') : t('common.submit')} className="primary" loading={loading.update} />
          <LinkButton
            path={routes.products}
            label={t('common.cancel')}
            className="link text-danger fw-medium" />
        </div>
      </form >
    </>
  )
}
export default CreateProduct