import store from 'store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services';
import { SessionState } from 'utils/interfaces';

const initialState: SessionState = {
  isUserLoggedIn: store.get(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`) ? true : false,
  me: {},
  printerDevice: ''
}

export const setLoggedInUser = createAsyncThunk('setLoggedInUser', async () => {
  return await authService.getMe()
})


//   const globalSettingsResponse: _Object = await authService.getGlobalSettings({ per_page: process.env.REACT_APP_PER_PAGE });

//   return globalSettingsResponse.items.reduce((acc: { [key: string]: _Object }, item: _Object) => {
//     acc[item.key] = item.value;
//     return acc;
//   }, {});
// });

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setAuth: (state, action) => {
      store.set(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9', action.payload)
      state.isUserLoggedIn = true
    },
    setPrintDevice: (state, action) => {
      state.printerDevice = action.payload
    },
    setDestroyAuth: (state) => {
      store.clearAll()
      state.isUserLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLoggedInUser.fulfilled, (state, action) => {
        state.me = action.payload
      })
    // .addCase(setGlobalSettingas.fulfilled, (state, action) => {
    //   state.globalSettings = action.payload
    // });
  },
});

export const { setAuth, setDestroyAuth, setPrintDevice } = sessionSlice.actions;

export default sessionSlice.reducer;

