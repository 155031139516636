import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { expenseService } from 'services'
import { _Object } from 'utils/interfaces'
import { routes, serialNumber, doDateFormatWithTime, strings, closeModal, handleFilters, getSortIcon } from 'utils'
import { LinkButton, SearchField, Button, Pagination, InputField, TextArea, Breadcrumbs } from 'views/components'

const CategoriesList = () => {
  const { t } = useTranslation()

  const [id, setId] = useState('');
  const [loading, setLoading] = useState<_Object>
    ({
      listing: false,
      edit: false,
      update: false
    });
  const [initialData, setInitialData] = useState<_Object>({})
  const [expenseCategories, setExpenseCategories] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    expenseService.getExpensesCategories({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setExpenseCategories(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    setInitialData({
      name: '',
      description: '',
    })
    if (id) {
      setLoading({ edit: true })
      expenseService.getExpenseCategoroyDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Title').required(t('category.title_required')),
    }),

    onSubmit: (values) => {
      setLoading({ update: true });

      if (id) {
        const params = {
          name: values.name,
          description: values.description
        }

        expenseService.updateExpenseCategories(id, params).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCategoryExpensesModal');
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })

      } else {
        expenseService.createExpenseCategories(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCategoryExpensesModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      }
    },
  })

  const deleteItem = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      expenseService.deleteExpenseCategories(id).then(() => {
        expenseService.getExpensesCategories(filterData).then((data: _Object) => {
          setExpenseCategories(data)
          setLoading({ listing: false })
        })
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        trails={[
          {
            label: t('common.expense'),
            path: `${routes.expense}`
          },
          {
            label: t('category.categories'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        <button type="button" onClick={() => {
          formik.resetForm(); setId('');
          setInitialData({
            name: '',
            description: '',
          })
        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCategoryExpensesModal">
          <i className="fa-solid fa-plus text-white"></i>{t('common.add_new')}</button>

        {/* Modal */}
        <div className="modal fade" id="addCategoryExpensesModal" tabIndex={-1} aria-labelledby="addCategoryExpensesModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading.edit ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="mb-0">{id ? t('expense.update_category') : t('expense.new_category')}</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <InputField
                  name="name"
                  label={t('common.title')}
                  required={true}
                  value={formik.values?.name}
                  onChange={formik.handleChange}
                  error={formik.touched?.name && formik.errors?.name}
                />

                <TextArea
                  rows={5}
                  name="description"
                  label={t('common.description')}
                  onChange={formik.handleChange}
                  placeholder={t('common.enter_description')}
                  value={formik.values.description}
                />
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.expenseCategories}`} label={t('common.cancel')} data-bs-dismiss="modal" className="link text-danger" />
                <Button type="submit" className="primary" name={id ? t('common.update') : t('common.submit')} loading={loading.update} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive" >
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th >#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                    {t('common.title')}
                    {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                  </button>
                </th>
                <th>{t('common.description')}</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    {t('common.updateDate')}
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {expenseCategories?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, expenseCategories?.pagination?.current_page, i)}</td>
                    <td>{item.name}</td>
                    <td className="break-spaces">{item.description || '-'}</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button type="button" onClick={() => setId(item.id)} id="addCategoryExpensesModal" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addCategoryExpensesModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <Button type="button" className="link text-danger p-0"
                            onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>

                    </td>
                  </tr>
                )
              })}
              {loading.listing === false && expenseCategories?.items?.length === 0 && <tr>
                <td colSpan={6} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>

      {expenseCategories?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={expenseCategories?.pagination?.current_page}
          per_page={expenseCategories?.pagination?.per_page}
          total_items={expenseCategories?.pagination?.total_items}
          total_pages={expenseCategories?.pagination?.total_pages}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

    </>
  )
}
export default CategoriesList;