import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { _Object } from 'utils/interfaces';
import { productService } from 'services';
import { capitalizeFirstLetter, doAmountFormat, updateInputFieldValue } from 'utils';
import { addToCart, setQuantity } from 'redux/slices/cart.slice';

import { Button, SearchField, Quantity } from 'views/components';
import { toast } from 'react-toastify';

const SearchAndModel = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cart } = useSelector((state: _Object) => state.cart)
  const { me } = useSelector((state: _Object) => state.session);

  const [products, setProducts] = useState<any>([])
  const [loading, setLoading] = useState<_Object>({
    search: false,
    summary: false,
    discountbtn: false
  })
  const [querySearch, setQuerySearch] = useState(false);
  const [modalState, setModalState] = useState(false);

  const [checkProducts, setCheckProducts] = useState<_Object>({ item: [] });

  useEffect(() => {
    const localdata: any = localStorage.getItem('cartLineItems')
    if (JSON.parse(localdata)?.length > 0 && cart?.lineItems?.length === 0) {
      dispatch(addToCart(JSON.parse(localdata)))
    }
  }, [])

  const handleShow = () => {
    setModalState(!modalState);
    setProducts([])
  };

  const formik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (formik.values.q.trim() !== '') {
        getProducts();
      }
    },
  });

  const getProducts = () => {
    setLoading({ search: true })
    formik.values.q &&
      productService.getProducts({ q: formik.values.q.trim() }).then((data: _Object) => {
        setQuerySearch(true)
        setLoading({ search: false })
        if (data?.items?.length === 0) {
          toast.error(
            `${t('common.productNotFound')}`,
            {
              autoClose: 3000,
            }
          );
        }
        if (data?.items?.length === 1) {
          setProducts(data.items)
          const { items } = data
          if (items[0]?.stock_status === 'outofstock') {
            toast.error('This product is currently out of stock', {
              autoClose: 3000,
            })
          } else {
            dispatch(addToCart({
              id: items[0]?.id,
              sku: items[0]?.sku,
              name: items[0]?.name,
              stock: items[0]?.stock_status,
              price: items[0]?.discounted_price ? items[0]?.discounted_price : items[0]?.regular_price,
              tax_rate: items[0]?.tax,
              brand_name: items[0]?.brand?.name,
              tax_rate_id: items[0]?.tax_rate_id,
              description: items[0]?.description,
            }))
          }
          // formik.resetForm()
          formik.setFieldValue('q', '');
        } else {
          const updatedProducts = data?.items?.map((item: any) => {
            const newQuantity = item?.quantity ? item?.quantity : 1;
            return { ...item, quantity: newQuantity };
          });
          handleShow()
          setProducts(updatedProducts)
        }
      })
  }

  const handleCheckBox = (e: any, item: any) => {
    if (e.target.checked) {
      setCheckProducts((prev: any) => ({
        ...prev,
        item: [
          ...prev.item,
          {
            ...item,
            price: item.discounted_price ? item.discounted_price : item.regular_price
          }
        ]
      }));
    } else {
      const filterData = checkProducts?.item?.filter((data: any) => data?.id !== item?.id);
      const updatedItem = filterData.map((data: any) => ({
        ...data,
        price: data.discounted_price ? data.discounted_price : data.regular_price
      }));

      setCheckProducts({ item: updatedItem });
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={`form-loading ${loading.search == true ? 'btn-loading' : ''}`}>
        <SearchField
          name="q"
          disabled={!formik.values.q}
          autoFocus
          type="search"
          autoComplete="off"
          value={formik.values.q}
          onChange={(e: _Object) => {
            if (!e.target.value) {
              setProducts([])
            }
            setQuerySearch(false)
            formik.setFieldValue('q', e.target.value)
          }}
        />
      </form>

      {me?.client?.pos_system_type != 3 && formik?.values?.q && loading.search == false && products?.length === 0 && querySearch ?
        <p className="text-center mt-3">{t('common.no_record')}</p>
        :
        me?.client?.pos_system_type != 3 && cart.lineItems?.length === 0 && <p className="text-center mt-3">{t('checkout.cart_empty')}</p>
      }

      {/* Search modal */}
      {formik?.values?.q && products?.length > 1 &&
        <div className={`modal fade ${modalState ? ' show d-block' : ' d-none'}`} id="exampleModal" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="mb-0">{t('common.search_result')}</h4>
                <button type="button" className="btn-close" onClick={handleShow} data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="table-responsive sales-table">
                <table className="table table-striped caption-top sales-table">
                  <thead className="sticky-top">
                    <tr>
                      <th>{t('common.item')}</th>
                      <th style={{ width: '120px' }}>{t('common.price')}</th>
                      <th style={{ width: '160px' }}>{t('common.quantity')}</th>
                      <th className="text-end" style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td className="break-spaces">
                            {capitalizeFirstLetter(item?.name)}
                            {item.size && item.color &&
                              <>
                                <span className="d-block text-secondary">{t('checkout.size')}:</span>
                                <span className="d-block text-secondary">{t('checkout.colour')}:</span>
                              </>
                            }
                            <small className="d-block text-secondary">{t('common.sku')}: {item?.sku}</small>
                          </td>
                          <td>
                            {doAmountFormat(item.discounted_price ? item.discounted_price : item.regular_price)}
                            {item?.tax_rate?.rate &&
                              <small className="d-block text-secondary">{`${item?.tax_rate?.name || item.tax_rate?.title?.toUpperCase()} ${item?.tax_rate?.rate}%`}</small>}
                          </td>
                          <td>
                            <Quantity
                              id={`qty-${item.id}`}
                              onChange={(e: _Object) => dispatch(setQuantity({ 'quantity': e.target.value, id: item.id }))}
                              value={item.quantity}
                              incrementQuantity={() => {
                                item.quantity += 1
                                updateInputFieldValue(`qty-${item.id}`, item.quantity)
                              }}

                              decrementQuantity={() => {
                                if (item.quantity > 1) {
                                  item.quantity -= 1
                                  updateInputFieldValue(`qty-${item.id}`, item.quantity)
                                }
                              }}
                            />
                          </td>

                          <td className="text-center">
                            <input
                              className="form-check-input mt-0"
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                              onChange={(e: any) => handleCheckBox(e, item)}
                              disabled={item.stock_status === 'outofstock'}
                            />

                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              <div className="modal-footer">
                <Button type="button" className="link text-danger" onClick={handleShow}>
                  {t('common.cancel')}
                </Button>

                <Button
                  type="button"
                  className="primary"
                  disabled={checkProducts?.item?.length === 0}
                  loading={loading.search}
                  onClick={() => {
                    dispatch(addToCart(checkProducts.item));
                    formik.setFieldValue('q', '');
                    setCheckProducts({ item: [] })
                    setModalState(false);
                  }}
                >
                  {t('checkout.add_to_cart')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default SearchAndModel