import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userService } from 'services';
import { routes, doDateFormatWithTime, serialNumber, generateAvatar, strings, handleFilters, getSortIcon, capitalizeFirstLetter, getRoleLabel } from 'utils';
import { _Object } from 'utils/interfaces';
import { LinkButton, Pagination, SelectField, SearchField, Breadcrumbs, SEOHeader, ActiveFilters } from 'views/components';

const UsersList = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [users, setUsers] = useState<_Object>([])
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      role: '',
      status: '',
    }
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    userService.getUsers({ ...filterData, q: formik.values.q }).then((data: _Object) => {
      data.items && setUsers(data)
      setLoading({ listing: false });
    })
  }

  const formik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (formik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (formik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, formik.values.q])

  return (
    <>
      <SEOHeader title="Users" />
      <Breadcrumbs
        trails={[
          {
            label: t('user.users'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={formik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!formik.values.q}
              value={formik.values.q}
              onChange={(e: _Object) => {
                formik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <LinkButton path={`${routes.createUser}`} label={''} className="primary">
          <i className="fa-solid fa-plus text-white"></i> {t('user.add_user')}
        </LinkButton>
      </div>

      <div className="card border-0 my-3">
        <table className="card-body mb-0 table table-borderless table-striped order-listing">
          <thead>
            <tr className="bg-white">
              <th>#</th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-full_name' ? 'full_name' : '-full_name', setFilterData) }}>
                  {t('common.name')}
                  {getSortIcon(filterData.sort.includes('full_name') ? filterData.sort : '')}
                </button>
              </th>
              <th>{t('common.username')}</th>
              <th>{t('common.email')}</th>
              <th>{t('common.mobile')}</th>

              <th>
                <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.status?.length > 0 ? 'show filter-add' : 'show'}`}>
                  {t('common.status')}
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        name="status"
                        isClearable
                        menuIsOpen
                        isSearchableHide={true}
                        options={[
                          { label: 'Pending', value: 'pending' },
                          { label: 'Active', value: 'active' },
                        ]}
                        onChange={(val: _Object) => {
                          setFilterData((prev) => ({ ...prev, filter: { ...prev.filter, status: val?.value || '' } }))
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              status: val?.label || '',
                            },
                          }));
                        }}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                      />

                    </div>
                  </div>
                </button>
              </th>

              <th>
                <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.role?.length > 0 ? 'show filter-add' : 'show'}`}>
                  {t('common.role')}
                </button>
                <div className="dropdown">
                  <div className="dropdown-menu dropdown-menu-end">
                    <SelectField
                      name="role"
                      isSearchableHide={true}
                      options={[
                        { label: t('common.administrator'), value: 'administrator' },
                        { label: t('common.super_admin'), value: 'super_admin' },
                        { label: t('common.outlet_manager'), value: 'outlet_manager' },
                        { label: t('common.salesman'), value: 'salesman' },
                      ]}
                      onChange={(val: _Object) => {
                        setFilterData((prev) => ({ ...prev, filter: { ...prev.filter, role: val?.value || '' } }))
                        setFilteredLabel((prevLabel) => ({
                          filter: {
                            ...prevLabel.filter,
                            role: val?.label || '',
                          },
                        }));
                      }}
                      isClearable
                      menuIsOpen
                      getOptionLabel={(option: _Object) => option.label}
                      getOptionValue={(option: _Object) => option.value}
                    />
                  </div>
                </div>
              </th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                  {t('common.createDate')}
                  {getSortIcon(filterData?.sort?.includes('created_at') ? filterData.sort : '')}
                </button>
              </th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData?.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                  {t('common.updateDate')}
                  {getSortIcon(filterData?.sort?.includes('updated_at') ? filterData?.sort : '')}
                </button>
              </th>
              <th className="action">{t('common.action')}</th>
            </tr>
          </thead>

          <tbody>
            {users?.items?.map((item: _Object, i: number) => {
              return (
                <tr className="align-middle" key={i}>
                  <td>{serialNumber(filterData.per_page, users?.pagination?.current_page, i)}</td>
                  <td>{generateAvatar(item.first_name, item.last_name)}</td>
                  <td>{item.username}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile_number}</td>
                  <td>{capitalizeFirstLetter(item.status ? item.status : 'pending')}</td>
                  <td>{getRoleLabel(item.role)}</td>
                  <td>{doDateFormatWithTime(item.created_at)}</td>
                  <td>{doDateFormatWithTime(item.updated_at)}</td>

                  <td>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <LinkButton path={`${routes.users}/edit/${item.id}`} label={''} className="link p-0 text-dark">
                          <i className="fa fa-pen"></i>
                        </LinkButton>
                      </li>
                    </ul>
                  </td>
                </tr>
              )
            })}

            {loading.listing === false && users?.items?.length === 0 && <tr>
              <td colSpan={9} className="text-center no-entry">
                <aside>
                  <i className="fa-solid fa-ban"></i>
                  {strings.noRecordFound}
                </aside>
              </td>
            </tr>}
          </tbody>
        </table>
      </div>

      {users?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={users?.pagination?.current_page}
          per_page={users?.pagination?.per_page}
          total_pages={users?.pagination?.total_pages}
          total_items={users?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default UsersList