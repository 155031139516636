import React, { useEffect, useRef, useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { taskService, userService } from 'services';
import { routes, doDateFormatWithTime, serialNumber, strings, handleFilters, closeModal, getSortIcon, getPriorityLabel } from 'utils';
import { _Object } from 'utils/interfaces';
import { SearchField, LinkButton, Pagination, SelectField, Button, TextArea, InputField, Breadcrumbs, SEOHeader, ActiveFilters } from 'views/components';
import { AsyncSelectField, RadioButton } from 'views/components/form-inputs';
import { useTranslation } from 'react-i18next';

const TaskList = () => {
  const selectAsyncSelect: any = useRef([])
	const { t } = useTranslation()

  const [id, setId] = useState('');
  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [tasks, setTasks] = useState<_Object>({ items: [], pagination: {} })
  const [taskCategory, setTaskCategory] = useState<_Object[]>([])
  const [users, setUsers] = useState<_Object>([])
  const [initialData, setInitialData] = useState<_Object>({})
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})

  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      priority: '',
      responsible_user_id: ''
    }
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    taskService.getTasks({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setTasks(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    setInitialData({
      title: '',
      duration: '',
      category_id: null,
      priority: 'normal',
      remind_at: '',
      repetition: '',
      responsible_user_id: '',
      description: '',
      status: 'active',
      type: 'onetime'
    })

    if (id) {
      setLoading({ edit: true });
      taskService.getTaskDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading({ edit: false });
      })
    }

    taskService.getTaskCategories({per_page: 99999}).then((data: _Object) => {
      setTaskCategory(data?.items)
    })

    userService.getUsers('').then((data: _Object) => {
      setUsers(data?.items)
    })

  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().label('Title').required(),
      responsible_user_id: Yup.string().label('Responsible').required(),
      duration: Yup.number()
        .label('Duration')
        .min(1, 'Duration must be greater than or equal to 1')
        .required(),
    }),

    onSubmit: (values) => {
      setLoading({ update: true });
      if (id) {
        delete values.id
        delete values.created_at
        delete values.activities
        delete values.media
        delete values.reminders
        delete values.categories
        delete values.user
        delete values.updated_at

        taskService.updateTask(id, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addTaskModal');
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      } else {

        taskService.createTask(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addTaskModal');
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      }
    }
  })

  const deleteUser = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true });
      taskService.deleteTask(id).then(() => {
        handleCallApi();
        setLoading({ listing: false });
      })
    }
  }

  const userOption = async (userValue: string) => {
    const data: _Object = await userService.getUsers({ q: userValue })
    return data.items?.map((item: _Object) => { return { label: item.full_name, value: item.id } })
  }

  return (
    <>
      <SEOHeader title="Task" />

      <Breadcrumbs
        trails={[
          {
            label: 'Tasks',
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <div className="d-flex justify-content-end gap-2">
          <button type="button" onClick={() => {
            formik.resetForm(); setId('');
            setInitialData({
              title: '',
              duration: '',
              priority: 'normal',
              remind_at: '',
              repetition: '',
              responsible_user_id: '',
              category_id: null,
              description: '',
              status: 'active',
            })
          }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addTaskModal">
            <i className="fa-solid fa-plus text-white"></i> Add Task
          </button>

          {/* Modal */}
          <div className="modal fade" id="addTaskModal" tabIndex={-1} aria-labelledby="addTaskModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <form className={`modal-content  ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
                <div className="modal-header">
                  <aside>
                    <h4 className="mb-0">{id ? 'Update task' : 'New task'}</h4>
                  </aside>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <InputField
                      className="col-6"
                      name="title"
                      label="Title"
                      required={true}
                      value={formik.values?.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.title && formik.errors.title}
                    />

                    <InputField
                      className="col-6"
                      type="number"
                      name="duration"
                      label="Duration (in minutes)"
                      required={true}
                      value={formik.values?.duration}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.duration && formik.errors.duration}
                    />

                    <SelectField
                      className="col-6"
                      name="priority"
                      label="Priority"
                      isClearable
                      options={[
                        { label: 'Normal', value: 'normal' },
                        { label: 'Urgent', value: 'urgent' },
                        { label: 'High', value: 'high' },
                        { label: 'Low', value: 'low' },
                        { label: 'Medium', value: 'medium' },
                      ]}
                      value={{ value: formik.values.priority || 'normal' }}
                      onChange={(e: _Object) => {
                        formik.setFieldValue('priority', e?.value);
                      }}
                      onBlur={formik.handleBlur}
                      getOptionLabel={(option: _Object) => option.label}
                      getOptionValue={(option: _Object) => option.value || option.label}
                    />

                    <div className="col-6">
                      <AsyncSelectField
                        cacheOptions
                        required={true}
                        isClearable={false}
                        label="Responsible"
                        name="responsible_user_id"
                        loadOptions={userOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={users?.map((item: _Object) => {
                          return {
                            label: item?.full_name,
                            value: item?.id
                          }
                        })}
                        onChange={(val: _Object) => {
                          formik.setFieldValue('responsible_user_id', val?.value)
                          formik.setFieldValue('responsible_user', { name: val?.label, id: val?.value })
                        }}
                        value={
                          formik.values.responsible_user?.full_name &&
                          {
                            label: formik.values.responsible_user?.full_name || formik.values.responsible_user?.label,
                            value: formik.values.responsible_user?.id || formik.values.responsible_user?.value
                          }
                        }
                        error={formik.touched.responsible_user_id && formik.errors.responsible_user_id}
                      />
                    </div>

                    <SelectField
                      className="col-6"
                      isClearable
                      label="Category"
                      name="category_id"
                      options={taskCategory?.map((item: any) => ({ label: item?.title, value: item?.id }))}
                      // value={taskCategory?.filter((obj: _Object) => obj?.id === formik.values.category_id)}
                      value={{ value: formik.values.category_id }}
                      onChange={(val: _Object) => {
                        formik.setFieldValue('category_id', val?.value || null)
                      }}
                      getOptionLabel={(option: _Object) => option?.label}
                      getOptionValue={(option: _Object) => option?.value}
                    />

                    {/* <SelectField
                      name="remind_at"
                      label="Reminders"
                      options={[
                        { label: '5 minutes before', value: 5 },
                        { label: '10 minutes before', value: 10 },
                        { label: '30 minutes before', value: 30 },
                        { label: '1 hour before', value: 60 },
                        { label: '1 day before', value: 1440 },
                      ]}
                      onChange={(e: _Object) => {
                        formik.setFieldValue('remind_at', e?.value || '')
                      }}
                      // value={{ value: formik.values.remind_at }}
                      onBlur={formik.handleBlur}
                      getOptionLabel={(option: _Object) => option.label}
                      getOptionValue={(option: _Object) => option.value}
                      isClearable
                    /> */}

                    <SelectField
                      className="col-6"
                      name="repetition"
                      label="Repeat"
                      options={[
                        { label: 'Daily', value: 'daily' },
                        { label: 'Weekly', value: 'weekly' },
                        { label: 'Monthly', value: 'monthly' },
                        { label: 'Quarterly', value: 'quarterly' },
                        { label: 'Half Yearly', value: 'half_yearly' },
                        { label: 'Yearly', value: 'yearly' },
                      ]}
                      onChange={(e: _Object) => {
                        formik.setFieldValue('repetition', e?.value || '')
                      }}
                      value={{ value: formik.values.repetition }}
                      onBlur={formik.handleBlur}
                      getOptionLabel={(option: _Object) => option.label}
                      getOptionValue={(option: _Object) => option.value}
                      isClearable
                    />

                    <RadioButton
                      name="type"
                      className="col-12"
                      label="Recipient type"
                      required={true}
                      options={[
                        { label: 'One time', value: 'onetime' },
                        { label: 'Repeat', value: 'repeat' },
                      ]}
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      error={formik?.touched.type && formik.errors.type}
                    />

                    <TextArea
                      rows={5}
                      name="description"
                      label="Description"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Enter description"
                      value={formik.values.description}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <LinkButton path={`${routes.tasks}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger" />
                  <Button type="submit" className="primary" name={id ? 'Update' : 'Submit'} loading={loading.update} />
                </div>
              </form>
            </div>
          </div>

          <LinkButton
            path={`${routes.taskCategories}`}
            className="link text-dark text-decoration-none fw-medium"
            label="Categories"
          />
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped order-listing">
            <thead>
              <tr className="bg-white">
                <th>#</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-title' ? 'title' : '-title', setFilterData) }}>
                    Title
                    {getSortIcon(filterData.sort.includes('title') ? filterData.sort : '')}
                  </button>

                </th>

                <th>Duration</th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.priority?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    Priority
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        name="priority"
                        isSearchableHide={true}
                        options={[
                          { label: 'Normal', value: 'normal' },
                          { label: 'Urgent', value: 'urgent' },
                          { label: 'High', value: 'high' },
                          { label: 'Low', value: 'low' },
                          { label: 'Medium', value: 'medium' },
                        ]}
                        onChange={(e: _Object) => {
                          setFilterData((prev) => ({ ...prev, filter: { ...prev.filter, priority: e?.value || '' } }))
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              priority: e?.label || '',
                            },
                          }));
                        }}
                        menuIsOpen
                        isClearable
                        hideSelectedOptions={false}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                        menuPlacement="auto"
                      />
                    </div>
                  </div>
                </th>

                {/* <th style={{ width: '14rem' }}>Responsible</th> */}
                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.responsible_user_id?.length > 0 ? 'show filter-add' : 'show'}`} data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    Responsible
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <AsyncSelectField
                        isClearable
                        cacheOptions
                        menuIsOpen={true}
                        loadOptions={userOption}
                        name="responsible_user_id"
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={users?.map((item: _Object) => {
                          return {
                            label: item?.full_name,
                            value: item?.id,
                          }
                        })}
                        onChange={(val: _Object) => {
                          setFilterData((prevData) => ({
                            ...prevData,
                            filter: {
                              ...prevData.filter,
                              responsible_user_id: val?.value || ''
                            }
                          }))
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              responsible_user_id: val?.label || '',
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.category_id ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    Category
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        menuIsOpen
                        className="col"
                        options={taskCategory.map((item: _Object) => { return { label: item.title, value: item.id } })}
                        onChange={(e: _Object) => {
                          setFilterData((prev) => ({
                            ...prev,
                            filter: {
                              ...prev.filter,
                              category_id: e?.value || ''
                            }
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              category_id: e?.label || '',
                            },
                          }));
                        }}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                        isClearable
                      />
                    </div>
                  </div>
                </th>

                <th>Description</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">Action</th>
              </tr>
            </thead>

            <tbody>
              {tasks?.items?.map((item: _Object, i: number) => {

                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, tasks?.pagination?.current_page, i)}</td>
                    <td>{item.title}</td>
                    <td>{item.duration}</td>
                    <td>{getPriorityLabel(item.priority)}</td>
                    <td>{item?.responsible_user?.full_name}</td>
                    <td>{item?.task_category?.title || '-'}</td>

                    <td className="break-spaces">{item.description || '-'}</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button type="button" onClick={() => setId(item.id)} className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addTaskModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <Button type="button" className="link text-danger p-0" onClick={() => deleteUser(item.id)}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </Button>
                        </li>
                      </ul>

                    </td>
                  </tr>
                )
              })}
              {loading.listing === false && tasks?.items?.length === 0 && <tr>
                <td colSpan={10} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {tasks?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={tasks?.pagination?.current_page}
          per_page={tasks?.pagination?.per_page}
          total_pages={tasks?.pagination?.total_pages}
          total_items={tasks?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default TaskList