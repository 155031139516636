import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import { productService, orderService, locationService, inventoryService } from 'services';

import { capitalize, capitalizeFirstLetter, routes, stock_status } from 'utils';
import { _Object } from 'utils/interfaces';
import { AsyncSelectField, SearchField } from 'views/components/form-inputs';
import { Breadcrumbs, Button, InputField, SEOHeader, SelectField, TextArea, } from 'views/components';

const CreateInventory = () => {
  const selectAsyncSelect: any = useRef([])
  const { t } = useTranslation()

  const [loading, setLoading] = useState<_Object>({
    search: false,
    btn: false,
    recent: false,
  })
  const [modalState, setModalState] = useState(false);

  const [products, setProducts] = useState<_Object | null>(null)
  const [orders, setOrders] = useState<_Object>([])
  const [locations, setLocations] = useState<_Object>([])
  // const [subLocations, setSubLocations] = useState<_Object>([])
  const [recentData, setRecentData] = useState<_Object>([])

  useEffect(() => {

    orderService.getOrders('').then((data: _Object) => {
      setOrders(data.items)
    })
    locationService.getLocations('').then((data: _Object) => {
      setLocations(data.items)
      // setSubLocations(data.items)
    })

    setLoading({ recent: true });
    inventoryService.getinventory({
      start_date: moment().format('YYYY-MM-DD'),
      per_page: 99999,
      end_date: moment().format('YYYY-MM-DD')
    }).then((data: _Object) => {
      setLoading({ recent: false });
      if (data && data.items && data.items.length > 0) {
        // Check if the value already exists in the state before adding it
        const filteredData = data.items.filter((item: any) => !recentData.some((existingItem: any) => existingItem.id === item.id));
        setRecentData((prev: _Object[]) => [...prev, ...filteredData]);
      }
    });
  }, [])

  const searchFormik = useFormik({
    initialValues: { q: '' },

    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        setLoading({ search: true });
        // productService.getProducts({ q: searchFormik.values.q }).then((data: _Object) => {
        productService.getProducts({ q: searchFormik.values.q.trim() }).then((data: _Object) => {
          setProducts(data?.items)
          if (data?.items?.length > 1) {
            setModalState(true);
          } else {
            formik.setFieldValue('product_id', data?.items[0]?.id);
            formik.setFieldValue('product', data?.items[0]);
          }
          setLoading({ search: false })
        })
      }
    },
  });

  const formik: any = useFormik({
    initialValues: {
      batchid: uuidv4(),
      product_id: '',
      quantity: '',
      location_id: '',
      sub_locationid: null,
      status: '',
      order_id: null,
      description: ''
    },
    validationSchema: Yup.object({
      location_id: Yup.string().label('Location').required(),
      status: Yup.string().label('Status').required(),
      quantity: Yup.number().label('Quantity').required()
        .min(0, 'Quantity must be greater than 0')
    }),
    onSubmit: (values: _Object, { resetForm }) => {
      setLoading({ btn: true });
      inventoryService.createInventory(values).then((data: _Object) => {
        setLoading({ btn: false });
        if (data.error === false) {

          const newData = {
            product: values.product,
            order: values.order,
            location: values.location,
            status: values.status,
            quantity: values.quantity,
          };

          setRecentData((prev: _Object[]) => [...prev, newData]);
          // resetForm({
          //   values: {
          //     ...values,
          //     product_id: '',
          //     quantity: '',
          //     description: '',
          //   },
          // });
          resetForm();
          setProducts(null)
          searchFormik.setFieldValue('q', '')
        }
      });
    },
  });

  useEffect(() => {
    if (formik?.values?.product?.manage_stock == 'false') {
      toast.error(
        'Product is not enabled for stock management.',
        {
          autoClose: 3000,
        }
      );
    }

  }, [formik?.values?.product])

  const locationOption = async (locationValue: string) => {
    const data: _Object = await locationService.getLocations({ q: locationValue })
    // setSubLocations(data.items)
    return data.items?.map((item: _Object) => { return { label: capitalize(item.name), value: item.id } })
  }

  const orderOption = async (orderValue: string) => {
    const data: _Object = await orderService.getOrders({ q: orderValue })
    return data.items?.map((item: _Object) => { return { label: capitalize(item.order_number), value: item.id } })
  }

  // const selectedLocation = subLocations?.find(
  //   (item: _Object) => item.id === formik?.values?.location_id
  // );

  // const options = selectedLocation?.sub_locations && selectedLocation.sub_locations.length > 0
  //   ? selectedLocation.sub_locations.map((location: _Object) => {
  //     return {
  //       label: location.name,
  //       value: location.id,
  //     };
  //   })
  //   : [];
  console.log('loading', loading);

  return (
    <>
      <SEOHeader title="Create inventory" />

      <Breadcrumbs
        trails={[
          {
            label: t('inventory.inventory'),
            path: `${routes.inventory}`
          },
          {
            label: `${t('inventory.add')}`,
            path: ''
          }
        ]}
        pageTitle={`${t('inventory.add')}`}
      />

      <div className="row new-inventory">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <div className="card">
            <div className="card-header p-3">
              <h4 className="mb-0">{`${t('inventory.add')}`}</h4>
            </div>

            <div className="card-body">
              <form onSubmit={searchFormik.handleSubmit} className={`form-loading ${loading.search == true ? 'btn-loading' : ''}`}>
                <SearchField
                  className="col-12"
                  name="q"
                  disabled={!searchFormik.values.q}
                  autoFocus={true}
                  type="search"
                  autoComplete="off"
                  value={searchFormik.values.q}
                  onChange={(e: _Object) => {
                    searchFormik.setFieldValue('q', e.target.value)
                    // if (searchFormik.values.q == '') {
                      setProducts(null)
                    // }
                  }}
                />
              </form>

              {/* Single product */}
              {searchFormik?.values?.q && products?.length === 1 &&
                <div className="position-relative">
                  <table className="table table-striped caption-top sales-table h-auto">
                    <tbody>
                      {products?.map((item: _Object, index: number) => {
                        return (

                          <tr key={index} className="align-middle">
                            <td className="text-start break-spaces" style={{ width: '100%' }}>
                              <h4 className="mb-3">{capitalizeFirstLetter(item.name)}</h4>
                              <div className="d-flex gap-4">
                                <small>{t('common.sku')}:&nbsp;{item.sku}</small>
                                <small>{t('common.stock')}:&nbsp;{stock_status(item.stock_status)} {item.manage_stock == 'true' && item.stock_status == 'instock' && <small>{item.stock}</small>}</small>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>}

              {searchFormik?.values?.q !== '' && loading.search === false && products?.length === 0 &&
                <p className="text-center mt-3">{t('common.no_record')}</p>}

              {/* Search modal */}
              {searchFormik?.values?.q && products?.length > 1 &&
                <div className={`modal fade ${modalState ? ' show d-block' : ' d-none'}`} id="exampleModal" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="mb-0">{t('common.search_result')}</h4>
                        <button type="button" className="btn-close" onClick={() => setModalState(!modalState)} data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>

                      <div className="table-responsive sales-table">
                        <table className="table table-striped caption-top sales-table">
                          <thead className="sticky-top">
                            <tr>
                              <th>{t('common.name')}</th>
                              <th style={{ width: '160px' }}>{t('common.sku')}</th>
                              <th style={{ width: '120px' }}>{t('common.stock')}</th>
                              <th className="text-end" style={{ width: '50px' }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {products?.map((item: any, i: number) => {
                              return (
                                <tr key={i}>
                                  <td className="break-spaces">{capitalizeFirstLetter(item.name)}</td>
                                  <td style={{ width: '200px' }}>{item.sku}</td>
                                  <td style={{ width: '200px' }}>{stock_status(item.stock_status)} {item.manage_stock == 'true' && item.stock_status == 'instock' && <small>{item.stock}</small>}</td>
                                  <td className="text-center">
                                    <input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      onChange={() => {
                                        formik.setFieldValue('product_id', item.id);
                                        formik.setFieldValue('product', item);

                                      }}
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="modal-footer">
                        <Button type="button" name={t('common.cancel')} className="link text-danger" onClick={() => setModalState(!modalState)} />
                        <Button
                          type="button"
                          name={t('common.submit')}
                          className="primary"
                          disabled={formik?.values?.product?.manage_stock === 'false' || !(formik.values.product_id)}
                          onClick={() => { setProducts([formik?.values?.product]); setModalState(!modalState) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>}

              <form className="modal-content" onSubmit={formik.handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <InputField
                      className="col-6"
                      type="number"
                      name="quantity"
                      label={t('common.quantity')}
                      required={true}
                      autoFocus={false}
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik?.values?.product?.manage_stock === 'false' || !(formik.values.product_id)}
                      error={formik.touched.quantity && formik.errors.quantity}
                    />

                    <div className="col-6">
                      <AsyncSelectField
                        isClearable
                        cacheOptions
                        required={true}
                        label={`${t('location.location')}`}
                        name="location_id"
                        placeholder={t('common.choose_option')}
                        loadOptions={locationOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={locations?.map((item: _Object) => { return { label: item.name, value: item.id } })}
                        onChange={(val: _Object) => {
                          formik.setFieldValue('location_id', val?.value || '')
                          formik.setFieldValue('location', { name: val?.label, id: val?.value })
                        }}

                        onBlur={formik.handleBlur}
                        disabled={formik?.values?.product?.manage_stock === 'false' || !(formik.values.product_id)}
                        error={formik.touched.location_id && formik.errors?.location_id}
                      />
                    </div>


                    <SelectField
                      name="status"
                      label={t('common.status')}
                      required={true}
                      className="col-6"
                      value={{ value: formik.values.status }}
                      onBlur={formik.handleBlur}
                      options={formik.values.order_id ? [
                        { label: t('inventory.return_restock'), value: 'return_restock' },
                        { label: t('inventory.sale'), value: 'sale', },
                      ] : [
                        { label: t('inventory.correction'), value: 'correction' },
                        { label: t('inventory.received'), value: 'recieved' },
                        { label: t('inventory.return_restock'), value: 'return_restock' },
                        { label: t('inventory.damaged'), value: 'damaged' },
                        { label: t('inventory.theft_or_loss'), value: 'theft_or_loss' },
                        { label: t('inventory.sale'), value: 'sale', },
                      ]}
                      onChange={(e: _Object) => {
                        formik.setFieldValue('status', e?.value || '')
                      }}
                      disabled={formik?.values?.product?.manage_stock === 'false' || !(formik.values.product_id)}
                      error={formik.touched.status && formik.errors.status}
                      isClearable
                    />


                    <div className="col-6">
                      <AsyncSelectField
                        isClearable
                        cacheOptions
                        label={t('order.order')}
                        name="order_id"
                        placeholder={t('common.choose_option')}
                        loadOptions={orderOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={orders?.map((item: _Object) => {
                          return {
                            label: item?.order_number, value: item?.id
                          }
                        })}
                        onChange={(val: _Object) => {
                          formik.setFieldValue('order_id', val?.value),
                            formik.setFieldValue('order', { order_number: val?.label, id: val?.value })
                          // formik.setFieldValue('status', val?.value ? 'sale' : '')
                        }}
                        disabled={!['return_restock', 'sale'].includes(formik.values.status) || formik?.values?.product?.manage_stock === 'false' || !(formik.values.product_id)}
                      />
                    </div>

                    <TextArea
                      className="col-12"
                      name="description"
                      label={t('common.note')}
                      onChange={formik.handleChange}
                      placeholder={t('checkout.enter_note')}
                      value={formik.values.description}
                      disabled={formik?.values?.product?.manage_stock === 'false' || !(formik.values.product_id)}

                    />
                  </div>
                </div>

                <div className="modal-footer justify-content-start">
                  <Button
                    className="primary"
                    name={t('common.submit')}
                    disabled={
                      formik?.values?.product?.manage_stock === 'false' ||
                      !(formik.values.product_id &&
                        formik.values.location_id &&
                        formik.values.status &&
                        formik.values.quantity)
                    }
                    loading={loading.btn}
                  />
                  {/* <LinkButton
                    path={routes.inventory}
                    label="Cancel"
                    className="link text-danger fw-medium" /> */}
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 recently-added">
          <div className="card">
            <div className="card-header p-3">
              <h4 className="mb-0">{t('inventory.recent')}
                <span className={`ms-4 ${loading.recent == true ? 'is-loading' : ''}`} />
              </h4>
            </div>

            <div className="card-body">
              <div className="position-relative">
                <table className="table table-striped caption-top sales-table h-auto">
                  <tbody>
                    {recentData?.length > 0 &&
                      recentData.map((item: _Object, key: number) => {
                        return (
                          <tr key={key} className="align-middle">

                            <td className="text-start break-spaces" style={{ width: '100%' }}>
                              <h4 className="mb-3">{capitalizeFirstLetter(item?.product?.name)}</h4>

                              <div className="d-flex gap-4">
                                <small>{t('common.sku')}:&nbsp;{item.product.sku}</small>
                                <small>{t('inventory.added_quantity')}:&nbsp;{item.quantity} </small>
                                {item?.order?.order_number && <small>{t('order.order')} #:&nbsp;{item?.order?.order_number} </small>}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateInventory