import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { expenseService } from 'services';
import { _Object, FilterDataType, PaginationType } from 'utils/interfaces';
import { routes, serialNumber, doDateFormatWithTime, strings, handleFilters, doAmountFormat, closeModal, getSortIcon, doDateFormat, } from 'utils';
import { LinkButton, SearchField, Button, Pagination, InputField, TextArea, SelectField, Breadcrumbs, SEOHeader, ActiveFilters, ReportExport } from 'views/components';
// import { Link } from 'react-router-dom';


// Define interfaces for better type safety
interface Expense {
  id?: string;
  category_id: string | null;
  title: string;
  amount: string;
  description: string;
  expense_date: string;
  reference_number: string;
  created_by?: string;
  created_at?: string;
  updated_at?: string;
  category?: Category;
}

interface Category {
  id: string;
  name: string;
}

interface ExpensesResponse {
  items: Expense[];
  pagination: PaginationType;
}

const ExpensesList = () => {
  const { t } = useTranslation()

  const [id, setId] = useState('');
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({
    listing: false,
    edit: false,
    update: false
  });
  const [expenses, setExpenses] = useState<ExpensesResponse>({ items: [], pagination: {} });
  const [categories, setCategories] = useState<Category[]>([]);
  const [initialData, setInitialData] = useState<Expense>({
    id: '',
    category_id: null,
    title: '',
    amount: '',
    description: '',
    expense_date: '',
    reference_number: '',
  });

  const [filterdLabel, setFilteredLabel] = useState<_Object>({})
  const [filterData, setFilterData] = useState<FilterDataType>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE || '10',
    sort: '-expense_date',
    filter: {
      category_id: ''
    }
  });

  const handleCallApi = () => {
    setLoading({ listing: true });
    expenseService.getExpenses({ ...filterData, q: searchFormik.values.q }).then((data) => {
      if (!data.error) {
        setExpenses(data)
      }
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])


  useEffect(() => {
    setInitialData({
      category_id: null,
      title: '',
      amount: '',
      description: '',
      expense_date: '',
      reference_number: '',
    });

    if (id) {
      setLoading({ edit: true })
      expenseService.getExpenseDetails(id).then((data) => {
        setInitialData(data);
        setLoading({ edit: false })
      });
    }

    expenseService.getExpensesCategories({ per_page: 99999 }).then((data) => {
      setCategories(data?.items);
    });
  }, [id]);


  const formik: any = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().label('Title').required(t('expense.title_required')),
      expense_date: Yup.string().label('Expense').required(t('expense.date_required')),
      amount: Yup.number()
        .label('Amount')
        .min(1, t('expense.amount_greater_then_zero'))
        .required(t('expense.amount_required'))
        .test('decimal-places', t('expense.amount_three_decimal'), value => {
          if (!value) return true;
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        })
    }),

    onSubmit: (values: any) => {
      values.reference_number = `${values.reference_number}`
      setLoading({ update: true });
      if (id) {
        delete values.id
        delete values.created_by
        delete values.created_at
        delete values.category
        delete values.updated_at
        delete values.client_id

        expenseService.updateExpense(id, values).then((data) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addExpensesModal');
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      } else {
        expenseService.createExpense(values).then((data) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addExpensesModal');
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      }
    },
  });

  const deleteItem = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      expenseService.deleteExpense(id).then(() => {
        handleCallApi();
        setLoading({ listing: false })
      });
    }
  };

  return (
    <>
      <SEOHeader title="Expenses" />

      <Breadcrumbs
        trails={[
          {
            label: t('common.expense'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <div className="d-flex align-items-center gap-2">
          {/* <Link
            to="#"
            className="primary text-decoration-none"
            data-bs-toggle="modal" data-bs-target="#export-report"
          >
            {t('common.export_report')}
          </Link> */}
          <button type="button" onClick={() => {
            formik.resetForm(); setId('');
            setInitialData({
              category_id: null,
              title: '',
              amount: '',
              description: '',
              reference_number: '',
              expense_date: '',
            })
          }}
            className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addExpensesModal">
            <i className="fa-solid fa-plus text-white"></i> {t('expense.add')}
          </button>

          {/* Modal */}
          <div className="modal fade" id="addExpensesModal" tabIndex={-1} aria-labelledby="addExpensesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <form className={`modal-content ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
                <div className="modal-header">
                  <aside>
                    <h4 className="mb-0">{id ? t('expense.update') : t('expense.new')}</h4>
                  </aside>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <InputField
                      className="col-6"
                      name="title"
                      label={t('common.title')}
                      required={true}
                      value={formik.values.title}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.title && formik.errors.title}
                    />

                    <InputField
                      className="col-6"
                      name="amount"
                      label={t('common.amount')}
                      type="number"
                      required={true}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={formik.touched.amount && formik.errors.amount}
                    />

                    <div className="form-group mb-3 col-6">
                      <label className="label-form mb-1">{t('expense.date')} {<span className="text-danger">*</span>}</label>

                      <DatePicker
                        dateFormat="MMM dd, yyyy"
                        placeholderText="Enter here"
                        onChange={(value) => {
                          const selectedDate = moment(value).format('YYYY-MM-DD');
                          formik.setFieldValue('expense_date', selectedDate);
                        }}
                        maxDate={new Date()}
                        selected={formik.values.expense_date ? moment(formik.values.expense_date, 'YYYY-MM-DD').toDate() : null}
                      />

                      {formik.touched.expense_date && formik.errors.expense_date && (
                        <span className="invalid-feedback text-danger d-block mt-1">
                          {formik.errors.expense_date}
                        </span>
                      )}
                    </div>


                    <InputField
                      className="col-6"
                      type="number"
                      name="reference_number"
                      label={`${t('common.reference')} #`}
                      value={formik.values.reference_number}
                      onChange={formik.handleChange}
                    />

                    <SelectField
                      isClearable
                      label={t('category.category')}
                      name="category_id"
                      options={categories?.map((item) => ({ label: item?.name, value: item?.id }))}
                      // value={categories?.filter((obj: _Object) => obj.id === formik.values.category_id)}
                      value={{ value: formik.values.category_id }}
                      onChange={(e: { value: string | null }) => {
                        formik.setFieldValue('category_id', e?.value || null)
                      }}
                      getOptionLabel={(option: { label: string }) => option?.label}
                      getOptionValue={(option: { value: string }) => option?.value}
                    />

                    <TextArea
                      rows={5}
                      name="description"
                      label={t('common.description')}
                      onChange={formik.handleChange}
                      placeholder={t('common.enter_description')}
                      value={formik.values.description}
                      error={formik.touched.description && formik.errors.description}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <LinkButton path={`${routes.expense}`} label={t('common.cancel')} data-bs-dismiss="modal" className="link text-danger" />
                  <Button type="submit" className="primary" name={id ? t('common.update') : t('common.submit')} loading={loading.update} />
                </div>
              </form>
            </div>
          </div>
          <LinkButton
            path={`${routes.expenseCategories}`}
            className="link text-dark text-decoration-none fw-medium"
            label={t('category.categories')}
          />
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th>#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-title' ? 'title' : '-title', setFilterData) }}>
                    {t('common.title')}
                    {getSortIcon(filterData.sort.includes('title') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-amount' ? 'amount' : '-amount', setFilterData) }}>
                    {t('common.amount')}
                    {getSortIcon(filterData.sort.includes('amount') ? filterData.sort : '')}
                  </button>
                </th>
                <th>{t('common.reference')} #</th>
                <th>{t('category.category')}</th>
                <th>{t('common.description')}</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-expense_date' ? 'expense_date' : '-expense_date', setFilterData) }}>
                    {t('expense.date')}
                    {getSortIcon(filterData.sort.includes('expense_date') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {expenses?.items?.map((item, i: number) => {
                return (
                  <tr key={i}>
                    <td>{serialNumber(parseInt(filterData.per_page), expenses?.pagination?.current_page || 0, i)}</td>
                    <td >{item?.title}</td>
                    <td>{doAmountFormat(item?.amount)}</td>
                    <td>{item?.reference_number || '-'}</td>
                    <td>{item?.category?.name || '-'}</td>
                    <td className="break-spaces">{item?.description || '-'}</td>
                    <td>{doDateFormat(item.expense_date)}</td>
                    <td>{doDateFormatWithTime(item?.created_at || '')}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id || '')} id="addExpensesModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addExpensesModal">
                            <i className="fa fa-pen"></i>
                          </button>

                        </li>
                        <li className="list-inline-item">
                          <Button type="button" className="link text-danger p-0"
                            onClick={() => deleteItem(item.id || '')}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </li>
                      </ul>

                    </td>
                  </tr>
                )
              })}
              {loading.listing === false && expenses?.items?.length === 0 && <tr>
                <td colSpan={9} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>

      {(expenses?.pagination?.total_pages || 0) > 1 &&
        <Pagination
          current_page={expenses?.pagination?.current_page}
          per_page={expenses?.pagination?.per_page}
          total_pages={expenses?.pagination?.total_pages}
          total_items={expenses?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

      <ReportExport
        title="Export report expenses"
        type="expenses"
      />
    </>
  );
};
export default ExpensesList