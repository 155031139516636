import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom'

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { authService } from 'services';

import { Button, InputField, LinkButton, SEOHeader } from 'views/components'
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  // const navigate = useNavigate()
	const { t } = useTranslation()

  const query = new URLSearchParams(location.search)
  const id = query.get('id')
  const key = query.get('key')

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
      id: id,
      key: key
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      new_password: Yup.string().required(),
      confirm_password: Yup.string().required(),
    }),

    onSubmit: async (values) => {
      setLoading(true)
      authService.updatePassword(values)
        .then((data: _Object) => {
          data.error === false
          // && navigate(`${routes.login}`)
          setLoading(false)
          formik.resetForm();
        })
    },
  })

  return (

    <>
      <SEOHeader title="Reset password" />
      <div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
        <div className="card border-0 position-relative">

          <div className="text-center">
            <img src="/assets/images/logo.svg" alt="" className="img-fluid w-50 mb-4" />
            <h4>{t('resetPassword.reset_password')}</h4>
            <p>{t('resetPassword.type_and_confirm')}</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              type="password"
              name="new_password"
              label={t('resetPassword.new_password')}
              required={true}
              value={formik.values.new_password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik?.touched.new_password && formik.errors.new_password ? true : false}
            />

            <InputField
              type="password"
              name="confirm_password"
              label={t('resetPassword.confirm_password')}
              required={true}
              value={formik.values.confirm_password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik?.touched.confirm_password && formik.errors.confirm_password ? true : false}
            />

            <div className="pos-justify pos-between">
              <Button
                type="submit"
                name={t('resetPassword.reset_password')}
                className="primary"
                loading={loading}
              />
              <LinkButton
                label={t('login.sign_in')}
                path={`${routes.login}`}
                className="link px-0"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword