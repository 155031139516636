import React from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { closeModal } from 'utils';
import { _Object } from 'utils/interfaces';
import { cartLineItemDiscount } from 'redux/slices/cart.slice';

import { Button, InputField } from 'views/components';

const CartDiscountModal = ({ amount }: _Object) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: { label: '', amount: '' },
    validationSchema: Yup.object({
      amount: Yup.number()
        .label('Discount amount')
        .positive('Amount must be greater than zero')
        .required('Amount is required')
        .test('isValidAmount: The discount amount must be less than from total amount.', function (value: any) {
          return parseFloat(value) <= amount;
        })
      ,
    }),
    enableReinitialize: true,
    onSubmit: (values: any) => {
      dispatch(cartLineItemDiscount(values));
      closeModal('cartitem-modal');

      formik.resetForm();
    },
  });
  return (
    <div className="modal fade" id="cartitem-modal" tabIndex={-1} aria-labelledby="todaySummaryModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">
              Add discount
            </h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

          </div>
          <form className="modal-body" onSubmit={formik.handleSubmit}>
            <InputField
              type="number"
              name="amount"
              required={true}
              label="Discount amount"
              onBlur={formik.handleBlur}
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && formik.errors.amount}
            />
            <div className="modal-footer">
              <Button
                name="Cancel"
                type="button"
                data-bs-dismiss="modal"
                className="link text-danger"
                onClick={() => formik.resetForm()}
              />
              <Button
                name="Submit"
                className="primary"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default CartDiscountModal