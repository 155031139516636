import React, { useState } from 'react';
import { useFormik } from 'formik';

import { SEOHeader } from 'views/components';
import PrinterAccess from './printer-access';
import { _Object } from 'utils/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { printReceipt } from 'utils';
import { orderService } from 'services';
import { addToCart, setCustomer } from 'redux/slices/cart.slice';

import SalesTemplate1 from './template1';
// import SalesTemplate2 from './template2';
// import SalesTemplate3 from './template3';
// import SalesTemplate4 from './template4';

const Sales = () => {
	const dispatch = useDispatch()
	const [btnLoading, setBtnLoading] = useState(false)
	const { cart } = useSelector((state: _Object) => state.cart)
	const { me, printerDevice } = useSelector((state: _Object) => state.session)

	const orderFormik = useFormik({
		initialValues: {
			amount: 0,
			order_note: '',
			payment_mode: '',
			payment_ref_number: '',
			payment_terms: '',
		},
		enableReinitialize: true,
		onSubmit: () => {
			placeOrder()
		},
	})

	const placeOrder = async () => {
		setBtnLoading(true)
		const payLoad = {
			customer: { id: cart.customer.id || null },
			status: 'pending_payment',
			order_items: cart.lineItems.map((item: _Object) => {
				return { quantity: item.quantity, discount: item.discount, product_id: item.id, }
			}),
			discount: cart?.discount || null,
			order_note: orderFormik.values.order_note || null,
			fee: 0,
			billing: {
				address1: cart.customer.address1 || null,
				address2: cart.customer.address2 || null,
				city: cart.customer.city || null,
				state: cart.customer.state || null,
				country: cart.customer.country || null,
				postcode: cart.customer.postcode || null,
			},
			payment: {
				amount: orderFormik.values.amount || 0,
				payment_mode: orderFormik.values.payment_mode || null,
				payment_terms: orderFormik.values.payment_terms || null,
				payment_ref_number: orderFormik.values.payment_ref_number || null,
			}
		}
		const data: _Object = await orderService.createOrder(payLoad)
		setBtnLoading(false)
		if (data?.error === false) {
			printReceipt(printerDevice, data.data, me)

			// doReceiptPrint(printerDevice, cart, data.data.created_at, globalSettings, data.data.order_number, orderFormik.values.payment_mode)
			dispatch(setCustomer({})) && dispatch(addToCart([]))
			// navigate(`${routes.orders}/${data?.data?.id}`)
			orderFormik.resetForm();
		}
	}

	// const selectTemplate = () => {
	// 	switch (me?.client?.pos_system_type) {
	// 		case 1:
	// 			return <SalesTemplate1 orderFormik={orderFormik} btnLoading={btnLoading} />;

	// 		case 2:
	// 			return <SalesTemplate2 orderFormik={orderFormik} btnLoading={btnLoading} />;

	// 		case 3:
	// 			return <SalesTemplate3 orderFormik={orderFormik} btnLoading={btnLoading} />;

	// 		case 4:
	// 			return <SalesTemplate4 orderFormik={orderFormik} btnLoading={btnLoading} />;
	// 	}
	// };


	return (
		<>
			<SEOHeader title="Checkout" />
			<SalesTemplate1 orderFormik={orderFormik} btnLoading={btnLoading} />
			{/* {selectTemplate()}
			<SalesTemplate2 orderFormik={orderFormik} btnLoading={btnLoading} />; */}
			{/* <SalesTemplate3 orderFormik={orderFormik} btnLoading={btnLoading} />; */}
			{/* <SalesTemplate4 orderFormik={orderFormik} btnLoading={btnLoading} />; */}
			<PrinterAccess />
		</>
	)

}
export default Sales