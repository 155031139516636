import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { _Object } from 'utils/interfaces';
import { setDestroyAuth } from 'redux/slices/session.slice';
import { routes } from 'utils';
import { LinkButton, Button } from 'views/components';
import { addToCart } from 'redux/slices/cart.slice';

const MoreFeature = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isUserLoggedIn, me } = useSelector((state: _Object) => state.session);

  const destroyAuth = () => {
    dispatch(setDestroyAuth())
    dispatch(addToCart([]))
    localStorage.clear()
    if (isUserLoggedIn === false) {
      navigate(`${routes.login}`)
    }
  }

  return (
    <>
      <div className="row w-50 mx-auto d-flex align-content-center h-100">
        <header>
          <h2 className="mb-0">Welcome back, {me?.first_name || 'Hey'}</h2>
        </header>

        <div className="col-6">
          <ul className="list-group">

            <li className="list-item border-2 border-bottom d-block py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.orders}`}
                label="Orders"
              >
                <i className="fa-solid fa-coins me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.inventory}`}
                label="Inventory">
                <i className="fa-solid fa-boxes-stacked me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.locations}`}
                label="Locations">
                <i className="fa-solid fa-map-location me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.settings}`}
                label="Settings">
                <i className="fa-solid fa-gear me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.expense}`}
                label="Expense">
                <i className="fa-solid fa-circle-dollar-to-slot me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item border-2 border-bottom d-block py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.customers}`}
                label="Customers">
                <i className="fa-solid fa-users me-2"></i>
              </LinkButton>
            </li>

          </ul>
        </div>

        <div className="col-6">
          <ul className="list-group">

            <li className="list-item border-2 border-bottom d-block py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.analytics}`}
                label="Analytics">
                <i className="fa-solid fa-chart-pie me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.products}`}
                label="Products">
                <i className="fa-solid fa-sheet-plastic me-2"></i>
              </LinkButton>
            </li>

            {/* <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.tags}`}
                label="Tags"
              >
                <i className="fa-solid fa-tags me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item d-block border-2 border-bottom py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.suppilers}`}
                label="Suppliers">
                <i className="fa-solid fa-truck-field me-2"></i>
              </LinkButton>
            </li> */}

            <li className="list-item border-2 border-bottom d-block py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.users}`}
                label="Users">
                <i className="fa-solid fa-users me-2"></i>
              </LinkButton>
            </li>

            <li className="list-item border-2 border-bottom d-block py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.taxRates}`}
                label="Tax">
                <i className="fa-solid fa-percent"></i>
              </LinkButton>
            </li>

            {/* <li className="list-item border-2 border-bottom d-block py-3">
              <LinkButton
                className="link text-dark text-decoration-none"
                path={`${routes.tasks}`}
                label="Tasks">
                <i className="fa-solid fa-tasks me-2"></i>
              </LinkButton>
            </li> */}

            <li className="list-item border-2 border-bottom d-block py-3">
              <Button className="link text-decoration-none text-start text-danger" onClick={() => destroyAuth()}>
                <i className="fa-solid fa-right-from-bracket"></i>Logout
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )

}
export default MoreFeature