import React from 'react';
import { Helmet } from 'react-helmet';
// import { useSelector } from 'react-redux';
import { _Object } from 'utils/interfaces';

const SEOHeader = (props: _Object) => {
  const { title } = props
  // const { me } = useSelector((state: _Object) => state.session)

  return (
    <Helmet>
      <title>{title} - PIE POS
        {/* {me?.client?.name ? `- ${me?.client?.name}` : '- PIE POS'} */}
      </title>
    </Helmet>
  )
}
export default SEOHeader;