import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class CategoryService extends CommonService {
  async getCategories(params: _Object | string) {
    return await this.get(`products/categories?${generateQueryParams(params)}`)
  }
  async getCategoryDetails(id: string) {
    return await this.get(`products/categories/${id}`)
  }
  async createCategory(parmas: { [key: string]: boolean | string | null }) {
    return await this.post('products/categories', parmas)
  }
  async updateCategory(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`products/categories/${id}`, params)
  }
  async deleteCategory(id: string) {
    return await this.delete(`products/categories/${id}`)

  }
}
export const categoryService = new CategoryService();
