import CommonService from './common.service';
// import { generateQueryParams } from 'utils';

class AuthService extends CommonService {

  async login(params: { [key: string]: string | boolean }) {
    return await this.post('login', params);
  }

  async forgotPassword(params: { [key: string]: string }) {
    return await this.post('reset-password-email', params)
  }

  async updatePassword(params: { [key: string]: string | null }) {
    return await this.post('update-password', params)
  }

  async getMe() {
    return await this.get('me',)
  }

  // async getGlobalSettings(params: _Object) {
  //   return await this.get(`global-settings?${generateQueryParams(params)}`)
  // }
  async addSettings(params: { [key: string]: string | null }) {
    return await this.post('global-settings', params)
  }
}

export const authService = new AuthService();

